.modal {  
  .modal-header {
    padding: 21px 27px 10px;
    border-bottom: 0px;
  }
  .modal-body {
    padding: 10px 27px 10px;
  }
  .modal-footer {
    padding: 20px 27px 21px;
    border-top: 0px;
  }
  .modal-title {
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000000;
  }
}
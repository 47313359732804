/* Custom HEADER CSS Style */

.bg-primary {
  background-color: var(--clear-blue) !important;
}

.navbar-dark {
  z-index: 2;
  .navbar-nav .nav-link {
    color: var(--white) !important;
  }

  .navbar-brand {
    color: var(--white);
    font-size: 29px;
    line-height: 1.15;
    margin-right: 40px;
    margin-left: 40px;
    padding: 29px 0;

    img {
      vertical-align: bottom;
      width: 245px;
      height: 41.4531px;
    }
  }
}

.dropdown-menu {
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
  right: auto;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}


/*.collapsing {
    -webkit-transition: .2m;
    transition: none;
    display: none;
}*/


.navbar-expand-lg {
  .form-inline {
    .form-control {
      display: inline-block;
      vertical-align: middle;
      background-color: var(--clear-blue);
      border-top: 0 transparent;
      border-left: 0 transparent;
      border-right: 0 transparent;
      border-bottom: 1px solid var(--white);
      border-radius: 0;
      padding: 24px 0;
      color: var(--white);
    }

    color: var(--white);
    padding: 0;
    margin: auto 45px;
    max-width: 400px;
    padding-top: 2px;

    .fas, .fa {
      color: var(--white);
      font-size: 20px;
      font-weight: 600;
      margin-top: 6px;
      margin-left: 7px;
    }

    .btn-link {
      border-top: 0 transparent;
      border-left: 0 transparent;
      border-right: 0 transparent;
      border-bottom: 1px solid var(--white);
      border-radius: 0;
      padding: .375rem 12px .375rem 18px
    }

    .form-control {
      &::placeholder {
        color: var(--white);
        opacity: 1;
      }

      &:focus {
        color: var(--white);
        background-color: transparent;
        border-color: var(--white);
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
      }
    }
  }

  .navbar-nav {
    /*.nav-link {
      &:hover {
        background-color: var(--sky-blue);
      }
    }*/
    .nav-item.show .nav-link {
      background-color: var(--sky-blue);
    }

    width: 100%;
  }
}

.navbar-dark .navbar-toggler-icon {
  /*background-image:url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");*/
  background-image: none !important;
}

.navbar-expand-lg .navbar-nav .nav-link {
  font-size: 1em;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  white-space: nowrap;
}

.navbar-dark .navbar-nav .search-form {
  display: none;
}

.navbar-links-custom .dropdown ul {
  text-align: center;

  li {
    float: none;
    display: inline-block;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 991.98px) {

  .container-fluid {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .navbar-expand-lg .form-inline {
    &.desktop {
      display: none;
    }

    color: var(--white);
    padding: 0;
    margin: 0;
    max-width: 100%;
  }

  .navbar-brand.menu-expanded {
    display: none;
  }

  .navbar-dark .navbar-nav {
    .nav-link {
      padding: 1em 0 0.8em 25px;
      border-bottom: solid 1px white;
      text-align: left;
    }

    .search-form {
      display: list-item;
    }
  }

  .navbar-expand-lg .form-inline {
    .form-control {
      padding-left: 6px;
    }

    .btn-link {
      padding: .375rem 12px .375rem 18px
    }
  }

  .navbar-dark .navbar-toggler {
    color: rgb(255, 255, 255) !important;
    border: 0 transparent;
    padding-right: 1em !important;

    object {
      -webkit-filter: invert(100%);
      width: 44px;
      height: 44px;
      pointer-events: none;
    }

    &:focus {
      color: rgb(255, 255, 255) !important;
      border: 0 transparent;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }

  .navbar {
    padding: 0 !important;
  }

  .navbar-brand {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }

  .dropdown-menu {
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    background-color: var(--clear-blue);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 0;
    border-bottom: solid 1px var(--white);
  }

  .dropdown-item {
    color: var(--white);
    font-size: 1em;
    min-width: 100%;
    height: 38px;
    padding: .25rem 1.5rem !important;
    border-radius: 0;

    &:hover, &:focus {
      background-color: var(--sky-blue);
      color: var(--white);
    }
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-expand-lg {
    width: 100%;
    padding: 0;

    .container-fluid {
      width: 100%;
      padding-right: 0;
      padding-left: 0;
      margin-right: 0;
      margin-left: 0;
    }

    .navbar-nav {
      .nav-link {
        height: 100px;
        padding-top: 40px;
      }

      .nav-item {
        border-left: solid 1px var(--carolina-blue);

        &.last {
          border-right: solid 1px var(--carolina-blue);
        }
      }
    }
  }

  .dropdown:hover .dropdown-menu, .btn-group:hover .dropdown-menu {
    display: block;
  }

  .dropdown-menu {
    margin-top: 0;
  }

  .dropdown-toggle {
    margin-bottom: 2px;
  }

  .navbar .dropdown-toggle, .nav-tabs .dropdown-toggle {
    margin-bottom: 0;
  }

  .dropdown-menu:before {
    content: "";
    top: -11px;
    left: calc(45% - 2px);
    position: absolute;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 11px solid var(--duck-egg-blue);
  }

  .dropdown-menu:after {
    content: "";
    top: -10px;
    left: 45%;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--white);
  }


  .dropdown-toggle::after {
    display: none;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .navbar-expand-lg {
    width: 100%;
    padding: 0;

    .container-fluid {
      width: 100%;
      padding-right: 0;
      padding-left: 0;
      margin-right: 0;
      margin-left: 0;
    }

    .navbar-nav {
      .nav-link {
        height: 100px;
        padding-top: 40px;
      }

      .nav-item {
        border-left: solid 1px var(--carolina-blue);

        &.last {
          border-right: solid 1px var(--carolina-blue);
        }
      }
    }
  }

  .dropdown:hover .dropdown-menu, .btn-group:hover .dropdown-menu {
    display: block;
  }

  .dropdown-toggle {
    margin-bottom: 2px;
  }

  .navbar .dropdown-toggle, .nav-tabs .dropdown-toggle {
    margin-bottom: 0;
  }

  .dropdown-menu:before {
    content: "";
    top: -11px;
    left: calc(45% - 2px);
    position: absolute;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 11px solid var(--duck-egg-blue);
  }

  .dropdown-menu:after {
    content: "";
    top: -10px;
    left: 45%;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--white);
  }

  .dropdown-toggle::after {
    display: none;
  }
}

/* MENU SPACING */
@media (min-width: 992px) and (max-width: 1049.98px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 8px;
    padding-right: 8px;
  }

  .navbar-dark .navbar-brand {
    margin-right: 15px;
    margin-left: 15px;
  }

  .navbar-expand-lg .form-inline {
    padding-top: 2px;
    margin: auto 15px;
  }

  .dropdown-menu {
    margin-top: -8px;
  }
}

@media (min-width: 1050px) and (max-width: 1199.98px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 15px;
    padding-right: 15px;
  }

  .navbar-dark .navbar-brand {
    margin-right: 25px;
    margin-left: 25px;
  }

  .navbar-expand-lg .form-inline {
    padding-top: 2px;
    margin: auto 25px;
  }

  .dropdown-menu {
    margin-top: -8px;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 25px;
    padding-right: 25px;
  }

  .dropdown-menu {
    margin-top: -8px;
  }
}

@media (min-width: 1441px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 45px;
    padding-right: 45px;
  }

  .dropdown-menu {
    margin-top: -8px;
  }
}

/* MENU */

@media (min-width: 992px) {
  .mobile-dropdown {
    display: none;
  }
  .dropdown-item, .dropdown-item:visited {
    color: var(--clear-blue);
    font-size: 1em;
    min-width: 125px;
    height: 38px;
    padding: .25rem 18px !important;
    border-radius: 3px;
    line-height: 1.95;
    letter-spacing: normal;

    &:focus, &:hover {
      background-color: var(--pale-grey);
      color: var(--clear-blue);
    }
  }
  .navbar-expand-lg {
    .dropdown-menu {
      box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16);
      border: solid 1px var(--duck-egg-blue);
      background-color: var(--white);
    }
  }
}
@media (max-width: 991px) {
  .navbar-expand-lg {
    .dropdown-menu {
      //box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16);
      //border: solid 1px var(--duck-egg-blue);
      //background-color: var(--white);
      box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0);
      border: solid 1px #d7dbdc00;
      background-color: transparent;
      border-bottom: solid 1px #ffffff;
    }
  }
  .dropdown-item, .dropdown-item:visited {
    //color: var(--clear-blue);
    //font-size: 1em;
    //min-width: 125px;
    //height: 38px;
    //padding: .25rem 18px !important;
    //border-radius: 3px;
    //line-height: 1.95;
    //letter-spacing: normal;

    &:focus, &:hover {
      //background-color: var(--pale-grey);
      //color: var(--clear-blue);
    }
  }

  .navbar-nav {
    .nav-item.dropdown {
      .mobile-dropdown {
        .open {
          display: none;
        }
        .closed {
          display: block;
        }
      }
      &.show {
        .mobile-dropdown {
          .open {
            display: block;
          }
          .closed {
            display: none;
          }
        }
      }
    }
  }
  .mobile-dropdown {
    display: block;
    top: 0;
    position: absolute;
    right: 0;
    z-index: 100;
    height: 53px;
    width: 80px;
    padding: 10px 20px;
    pointer-events: none;
    .icon {
      float: right;
    }
  }
  .navbar-toggler {
    padding: 0 0 0 20px;
  }

body {
width: 100vw;
height: 60vh;
animation: bugfix infinite 1s;
-webkit-animation: bugfix infinite 1s;
}

@keyframes bugfix {
  from {
    padding: 0;
  }

  to {
    padding: 0;
  }
}

@-webkit-keyframes bugfix {
  from {
    padding: 0;
  }

  to {
    padding: 0;
  }
}

#overlay-button {
position: absolute;
right: 2em;
top: 3em;
padding: 26px 11px;
z-index: 5;
cursor: pointer;
user-select: none;
}

#navbar-toggler-ico {
span {
  height: 4px;
  width: 35px;
  border-radius: 2px;
  background-color: white;
  position: relative;
  display: block;
  transition: all .8s ease-in-out;

  &:before {
    top: -10px;
    visibility: visible;
  }

  &:after {
    top: 10px;
  }

  &:before, &:after {
    height: 4px;
    width: 35px;
    right: 0;
    border-radius: 2px;
    background-color: white;
    position: absolute;
    content: "";
    transition: all .2s ease-in-out;
  }
}

&:hover span {
  background: #ffffff;

  &:before, &:after {
    background: #ffffff;
  }
}
}

.navbar-toggler[aria-expanded="true"] {
#navbar-toggler-ico {
  &:hover span {
    background: transparent;
  }

  span {
    background: transparent;

    &:before {
      transform: rotate(45deg) translate(7px, 7px);
      opacity: 1;
    }

    &:after {
      transform: rotate(-45deg) translate(7px, -7px);
    }
  }
}

z-index: 999999999;
position: fixed;
top: 20px;
height: 35px;
right: 0;
}

.navbar-brand.menu-expanded {
z-index: 999999999;
position: fixed;
top: 10px;
left: 0;
}

.navbar-collapse {
height: 100vh;
width: 100vw;
background: var(--clear-blue);
z-index: 9999999;
position: fixed;
top: 0;
left: 0;
}

.navbar-nav .dropdown-menu {
position: relative;
float: none;
}
}

@media(max-width: 991px) {
  .navbar-expand-lg {
  .form-inline {
    .form-control {
      padding: 44px 0 43px;
    }
  }
  }
}
.page.faq {
  .page-title {
    min-height: auto;
    p {
      font-size: 20.8px;
      font-weight: 400;
      line-height: 1.33;
    }
  }
  .accordion-toggle[aria-expanded="false"] {
    img.open {
      display: none;
    }
    img.closed {
      display: block;
    }
  }
  .accordion-toggle[aria-expanded="true"] {
    img.open {
      display: block;
    }
    img.closed {
      display: none;
    }
  }
  .accordion-toggle {
    font-size: 32.5px;
    font-weight: 600;
    line-height: 1.34;
    cursor: pointer;
    img.icon {
      float: right;
      height: 36px;
      width: 36px;
    }
    span {
      width: 90%;
      display: inline-block;
    }
  }
}
@media(max-width: 668px) {
  .page.faq {
    .accordion-toggle {
      span {
        width: 85%
      }
    }
  }
}

.home-section.faq {
  p {

    font-size: 16.7px;
    font-weight: 500;
    line-height: 1.33;
  }
  .subtitle {
    font-family: "Nunito Sans" !important;
    font-size: 20.8px !important;
    font-weight: 700 !important;
    line-height: 1.33 !important;
    margin-top: 30px !important;
    color: #000000 !important;
  }
}

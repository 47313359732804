/* Custom FOOTER CSS Style */

.container-fluid.footer {
  padding: 0;
  margin: 0;
}

footer {
  &.top {
    .form-group {
      width: 100%;
      height: 45px;
      margin-bottom: 12px;
      border-radius: 3px;
    }

    .form-control {
      width: 100%;
      height: 45px;
      font-size: 15px;
    }

    .form-group .btn {
      width: 100%;
      font-size: 1em;
      line-height: 1.33;
      letter-spacing: 0.5px;
      box-shadow: none;
    }
  }

  .mb-2 {
    font-size: 20.7px;
  }

  .mb-1 {
    font-size: 1em;
  }

  &.bottom {
    border-top: solid 1px rgba(215, 219, 220, 0.34);
    font-size: 1em;
    line-height: 1.33;

    .list-inline {
      opacity: 0.65;
    }

    img {
      vertical-align: bottom;
      height: 28px;
      margin-bottom: -3px;
      opacity: 1;
    }
  }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 991.98px) {
  footer {
    &.top {
      padding: 21px 45px 45px 45px;
    }

    .mb-2 {
      margin-top: 40px;
    }

    &.bottom {
      padding: 30px 15px 45px 15px;

      .text-inline {
        text-align: center !important;
      }
    }
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  footer {
    &.top {
      padding: 60px 100px 44px;
    }

    &.bottom {
      padding: 30px 100px;
    }
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  footer {
    &.top {
      padding: 60px 100px 44px;
    }

    &.bottom {
      padding: 30px 100px;
    }
  }
}

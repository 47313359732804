/* Custom Splash CSS Style */

.nonprofit-page {
  .container-fluid {
    padding: 79px 5% 29px 5% !important;
  }
  .header {
    .page-title {
      min-height: 100px;
      .rating-label {
        border-radius: 3px;
        vertical-align: center;
        padding: 5px 16px;
        font-size: 13.3px;
        font-weight: 700;
        text-transform: uppercase;
        border-color: var(--very-light-blue);
        letter-spacing: 0.5px;
        background-color: var(--very-light-blue);
        margin-bottom: 10px;
        display: inline-block;
        line-height: normal;
      }
      h1 {
        font-size: 40.7px;
        font-weight: 800;
        line-height: 1.33;
        letter-spacing: 0.5px;
        text-transform: capitalize;
        margin-bottom: 3px;
        margin-top: 24px;
      }
    }
    .nonprofit-detail {
      padding-bottom: 0;
      margin-bottom: 0;
      p {
        margin-bottom: 0;
      }
      .nonprofit-box-stars {
        margin-bottom: 29px;
        img {
          height: 23px;
        }
        .top-nonprofit {
          font-size: 16.7px;
          font-weight: 400;
          line-height: 1.33;
          position: relative;
          top: 4px;
          margin-right: 14px;
          white-space: nowrap;
          .top-nonprofit-icon {
            height: 16px;
            width: 16px;
            display: inline-block;
            position: relative;
            top: 2px;
            margin-right: 6px;
            svg {
              fill: #ffc12c;
            }
          }
        }
      }
      .logo-block {
        margin-right: 28px;
        width: 90px;
        img.logo {
          width: 89px;
          height: 89px;
          border-radius: 3px;
          border: solid 1px var(--duck-egg-blue);
          background-color: var(--white);
        }
      }
      .impact,
      .governance {
        padding-right: 10%;
        font-size: 20.8px;
        line-height: 1.33;
        .bold {
          font-weight: 800;
        }
        .nonprofit-value {
          margin-left: 2px;
          position: relative;
          top: -1px;
        }
      }
      .impact {
        margin-bottom: 15px;
      }
      .governance {
        margin-bottom: 29px;
      }
      .social-buttons {
        margin-top: 28px;
      }
      .donate-btn {
        &.request-rating {
          background-color: var(--very-light-blue);
          border-color: var(--very-light-blue) !important;
          &:hover, &:focus {
            border-color: var(--dark);
            background-color: var(--very-light-blue);
            color: var(--dark);
            text-decoration: none;
          }
        }
      }
      .nonprofit-programs {
        border-left: 1px solid var(--duck-egg-blue);
        padding-left: 45px;
        .mini-block {
          margin-bottom: 24px;
        }
      }
      .nonprofit-mission {
        padding-right: 30px;
        .mini-block {
          margin-bottom: 29px;
        }
      }
      .nonprofit-programs, .nonprofit-mission {
        .mini-block {
          .icon {
            float:left;
            height: 20px;
            width: 20px;
            margin-bottom: 0;
          }
          p {
            margin-left:34px;
            font-weight: 800;
            &.light {
              font-weight: 400;
              a {
                text-decoration: underline;
                color: var(--orange);
              }
            }
          }
        }
      }
    }
  }
  .home-section.calculator {
    .container {
      padding-top: 47px!important;
      padding-bottom: 47px!important;
      .message {
        font-size: 26px;
        font-weight: 600;
        line-height: 1.33;
        color: var(--white);
        .icon {
          width: 30px;
          height: 30px;
        }
        input, select, textarea{
          color: var(--white);
        }
        .input-group.prefix {
          border-bottom: 2px solid var(--white);
        }
      }
      .calculator-card {
        font-size: 40.7px;
        font-weight: 600;
        line-height: 1.23;
        color: var(--white);
      }
    }
    .icon {
      &:hover,
      &:active {
        filter: invert(100%)
              hue-rotate(0deg)
              saturate(0%)
              brightness(1000%)
              contrast(100%);
        background-color: transparent !important;
      }
    }
  }
  .home-section.rated-program {
    .container-fluid {
      padding: 0!important;
      img.rating-stars {
        height: 17px !important;
        margin-bottom: 13px;
        margin-right: 14px;
      }
    }
    .container-fluid-with-sidebar-right-background {
      position: relative;
    }
    .rated-sidebar-right-background {
      position: absolute;
      width: calc(100% - 1140px);
      right: 0;
      bottom: 0;
      top: 0;
      background-color: #f5f4f3;
    }
    .rated-sidebar {
      padding-top: 50px;
      padding-bottom: 50px;
      background-color: #f5f4f3;
      padding-left: 61px;
      .title {
        font-size: 20.8px;
        font-weight: 700;
        margin-bottom: 25px;
        line-height: 1.33;
        &.news {
          margin-top: 79px;
        }
      }
      .card {
        margin: 11px auto 11px auto;
        max-width: 415px;
        .title {
          text-decoration: none;
          text-align: left;
          font-size: 20.8px;
          font-weight: 700;
          margin-bottom: 0px;
          line-height: 1.33;
          a:hover, a:focus {
            text-decoration: none;
          }
        }
        .card-title {
          font-size: 16.7px!important;
          font-weight: 800;
          line-height: 1.33;
        }
        p.summary {
          opacity: 0.7;
          font-size: 13.3px;
          font-weight: 400;
          line-height: 1.33;
        }
        .impact-statement {
          font-size: 16.7px;
          font-weight: 400;
          line-height: 1.33;
          strong {
            font-weight: 800;
          }
          .small {
            font-size: 10px;
            margin-top: 10px;
          }
        }
      }
    }
    .rated-content {
      padding-top: 50px;
      padding-right: 0;
      padding-left: 0;
      .section-description {
        width: 100%;
      }
      .nav-tabs {
        height: auto;
        align-items: center;
        &.border-bottom {
          border-bottom: 1px solid var(--dark)!important;
          z-index: 10;
        }
        li {
          height: auto;
          a {
            margin-right: 18px;
            height: 51px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            font-size: 16.7px;
            font-weight: 600;
            line-height: 1.33;
            color: var(--black);
            padding: 15px 22px;
            &:hover {
              background-color: var(--ice-blue);
              color: var(--black);
              text-decoration: none;
            }
            display: block;
          }
          .active {
            background-color: var(--dark);
            color: var(--white);
            &:hover {
              background-color: var(--dark);
              color: var(--white);
              text-decoration: none;
            }
          }
        }
      }
      .tab-content {
        #governance-report,
        #impact-report,
        #methodology {
          padding-right: 5%;
        }
        color: var(--black);
        .download-analysis {
          font-size: 10px;
          font-weight: 400;
          margin: 10px auto 30px;
          display: block;
          padding: 8px 15px;
          color: var(--white);
          max-width: 235px;
          border-radius: 3px;
          background-color: var(--clear-blue);
        }
        .tab-title {
          margin-top: 31px;
          margin-bottom: 16px;
          font-size: 26px;
          font-weight: 700;
          line-height: 1.33;
          color: var(--black);
          i {
            font-size: 20px;
            font-weight: 400;
            opacity: .7;
          }
        }
        .section-title {
          margin-top: 15px;
          margin-bottom: 0;
          font-weight: 800;
          font-size: 16.7px;
          line-height: 1.33;
          color: var(--black);
        }
        .section-description {
          font-size: 16.7px;
          font-weight: 400;
          line-height: 1.33;
        }
        .section-title-tip {
          margin-top: 31px;
        }
        li.section-description {
          margin-bottom: 15px;
        }
        #methodology {
          margin-top: 0;
          margin-right: 0;
          .step {
            font-size: 20.8px;
            font-weight: 700;
            .icon-step {
              margin-bottom: 10px;
              filter: invert(0%) hue-rotate(0deg) saturate(0%) brightness(1000%) contrast(100%)
            }
          }
          .section-description.first {
            margin-bottom: 30px;
          }
          .section-description.last {
            margin-top: 30px;
          }
          .rating-process-row {
            padding: 0px;
          }
        }
      }
    }

  }
}
@media(max-width: 992px) {
  .nonprofit-page .home-section.rated-program .rated-sidebar {
    background-color: #fff;
    padding-left: 0px;
    .card.sidebar-rating-card {
      margin: 11px 0;
    }
  }
}
.governance-check-page {
  .header .page-title h1 {
    margin-bottom: 29px;
  }
  .calculator .container {
  }
  section.home-section .overlay.blue {
    background-color: var(--clear-blue);
  }
  .action-buttons {
    display: flex;
    align-items: center;
    .action-buttons-or {
      margin: 0 10px;
    }
  }
}
@media(max-width: 416px) {
.governance-check-page {
  .action-buttons {
    display: block;
  }
  .action-buttons-or {
    display: none;
  }
  #RatingRequest {
    margin-top: 15px;
  }
  p.small {
    display: none;
  }
}
}
.donate-sticky-footer {
  padding: 24px 7%;
  box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.1);
  border: solid 1px var(--duck-egg-blue);
  background-color: var(--white);
  .donate-btn {
    width: 150px;
  }
  .nonprofit-card {
    margin-top: 0;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
  }
  .title {
    font-size: 19px;
    font-weight: 800;
    line-height: 1.33;
  }
  .rating-stars {
    height: 19px;
    margin-bottom: 0;
  }
  .nonprofit-sticky-donate {
    display: flex;
    align-items: center;
  }
  .impact-statement-sticky-donate {
    font-size: 13.3px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    p {
      margin: 0!important;
    }
  }
  .sticky-donate-icon {
    margin-left: 30px;
    cursor: pointer;
    height: 20px;
    width: 20px;
  }
}
.ui-tooltip {
  width: 394px;
  height: 161px;
  box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16);
  padding: 19px;
  margin-left: -180px!important;
  background-color: var(--white);
}
.ui-tooltip-content::after,
.ui-tooltip-content::before {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
}
.ui-tooltip-content::after {
  transform: rotate(180deg);
  border-color: rgba(118, 118, 118, 0);
  border-top-color: var(--white);
  border-width: 10px;
  left: 50%;
  margin-left: -10px;
}
.ui-tooltip-content::before {
  transform: rotate(180deg);
  border-color: rgba(118, 118, 118, 0);
  border-top-color: var(--white);
  border-width: 11px;
  left: 50%;
  margin-left: -11px;
}

@media (max-width: 991.98px){
  .donate-sticky-footer {
    padding: 24px 1%;
  }
  .nonprofit-page {
    .header {
      .nonprofit-detail {
        .nonprofit-programs {
          border-left: 1px solid transparent;
          padding-left: 0;
          padding-top: 90px;
        }
      }
    }
    .home-section.rated-program {
      .rated-content {
        padding-left: 0;
        padding-right: 0;
      }
      .container-fluid {
        padding-bottom: 0!important;
      }
    }
  }
}
@media (min-width: 991.98px) and (max-width: 1199.98px){
  .nonprofit-page {
    .home-section.rated-program {
      .rated-content .nav-tabs li a {
        margin-right: 10px;
      }
    }
  }
}
.nonprofit-programs-collapse {
  display: none;
}
@media (max-width: 416px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .nonprofit-programs {
    display: none;
  }
  .nonprofit-programs-collapse.nonprofit-programs {
    display: block;
    padding-top: 0px !important;
  }
  .nonprofit-page .home-section.rated-program .rated-content {
    padding: 30px 15px 0;
  }
  .report-callout {
    display: none;
  }
  .nonprofit-page .home-section.rated-program .rated-content .tab-content #governance-report, .nonprofit-page .home-section.rated-program .rated-content .tab-content #impact-report, .nonprofit-page .home-section.rated-program .rated-content .tab-content #methodology {
    padding-right: 0px;
  }
  .nonprofit-page .home-section.rated-program .rated-content .nav-tabs li {
    margin-bottom: 5px;
  }
  .nonprofit-page .home-section.rated-program .rated-content .nav-tabs li a {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}
.rating-stars {
  height: 23px;
  margin-right: 14px;
}
p.governance-test {
  margin-bottom: 8px;
}
.nonprofit-page .home-section.rated-program .rated-content .tab-content .section-description.governance-source {
  font-size: 13.3px;
  margin-top: 14px;
}
@media(max-width: 668px) {
  .sticky-footer {
    padding: 0;
    visibility: hidden;
    border: 0;
    width: 0;
    height: 0;
  }
}
.nonprofit-page {
  .calculator-card-label {
    display: none;
  }
}
@media(max-width: 668px) {
  .nonprofit-page {
    .calculator-card {
      margin-top: 70px;
    }
  }
}
@media(max-width: 416px) {
  .nonprofit-page {
    .calculator-card {
      margin-top: 20px;
    }
  }
  .action-buttons {
    .btn {
      min-width: 230px;
    }
  }
}

//@media (min-width: 992px) and (max-width: 1049.98px) {}

//@media (min-width: 1050px) and (max-width: 1199.98px) {}

//@media (min-width: 1200px) and (max-width: 1440px) {}

//@media (min-width: 1441px) {}

/* Globals Universal Custom CSS Style */
:root {
  --dark: #182e34;
  --dark-grey-blue-two: #35565f;
  --dark-grey-blue: #28434a;
  --slate: #436a74;
  --metallic-blue: #4e7884;
  --grey-blue: #688c96;
  --greyblue: #81a1aa;
  --light-grey-blue: #a4bcc3;
  --light-blue-grey: #c5d7dc;
  --sapphire: #1a58ac;
  --clear-blue: #2883ff;
  --sky-blue: #76b0ff;
  --carolina-blue: #89bbff;
  --baby-blue: #9cc6ff;
  --very-light-blue: #d5e5ff;
  --pale-grey: #ebf3ff;
  --orange: #fc7f19;
  --mango: #ffa925;
  --macaroni-and-cheese: #ffc12c;
  --maize: #ffd041;
  --sun-yellow: #fee52f;
  --egg-shell: #fff9c3;
  --off-white-two: #fffde7;
  --ice-blue: #e4f0f4;
  --off-white: #f5f2ee;
  --duck-egg-blue: #d7dbdc;
  --white: #ffffff;
  --black: #000000; }

/* Fonts Custom CSS */
@font-face {
  font-family: 'Nunito Sans';
  src: url("/static/fonts/NunitoSans-ExtraLight.eot");
  src: url("/static/fonts/NunitoSans-ExtraLight.eot?#iefix") format("embedded-opentype"), url("/static/fonts/NunitoSans-ExtraLight.woff2") format("woff2"), url("/static/fonts/NunitoSans-ExtraLight.woff") format("woff"), url("/static/fonts/NunitoSans-ExtraLight.ttf") format("truetype"), url("/static/fonts/NunitoSans-ExtraLight.svg#NunitoSans-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("/static/fonts/NunitoSans-Light.eot");
  src: url("/static/fonts/NunitoSans-Light.eot?#iefix") format("embedded-opentype"), url("/static/fonts/NunitoSans-Light.woff2") format("woff2"), url("/static/fonts/NunitoSans-Light.woff") format("woff"), url("/static/fonts/NunitoSans-Light.ttf") format("truetype"), url("/static/fonts/NunitoSans-Light.svg#NunitoSans-Light") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("/static/fonts/NunitoSans-Black.eot");
  src: url("/static/fonts/NunitoSans-Black.eot?#iefix") format("embedded-opentype"), url("/static/fonts/NunitoSans-Black.woff2") format("woff2"), url("/static/fonts/NunitoSans-Black.woff") format("woff"), url("/static/fonts/NunitoSans-Black.ttf") format("truetype"), url("/static/fonts/NunitoSans-Black.svg#NunitoSans-Black") format("svg");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("/static/fonts/NunitoSans-ExtraLightItalic.eot");
  src: url("/static/fonts/NunitoSans-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("/static/fonts/NunitoSans-ExtraLightItalic.woff2") format("woff2"), url("/static/fonts/NunitoSans-ExtraLightItalic.woff") format("woff"), url("/static/fonts/NunitoSans-ExtraLightItalic.ttf") format("truetype"), url("/static/fonts/NunitoSans-ExtraLightItalic.svg#NunitoSans-ExtraLightItalic") format("svg");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("/static/fonts/NunitoSans-Italic.eot");
  src: url("/static/fonts/NunitoSans-Italic.eot?#iefix") format("embedded-opentype"), url("/static/fonts/NunitoSans-Italic.woff2") format("woff2"), url("/static/fonts/NunitoSans-Italic.woff") format("woff"), url("/static/fonts/NunitoSans-Italic.ttf") format("truetype"), url("/static/fonts/NunitoSans-Italic.svg#NunitoSans-Italic") format("svg");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("/static/fonts/NunitoSans-Bold.eot");
  src: url("/static/fonts/NunitoSans-Bold.eot?#iefix") format("embedded-opentype"), url("/static/fonts/NunitoSans-Bold.woff2") format("woff2"), url("/static/fonts/NunitoSans-Bold.woff") format("woff"), url("/static/fonts/NunitoSans-Bold.ttf") format("truetype"), url("/static/fonts/NunitoSans-Bold.svg#NunitoSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("/static/fonts/NunitoSans-SemiBold.eot");
  src: url("/static/fonts/NunitoSans-SemiBold.eot?#iefix") format("embedded-opentype"), url("/static/fonts/NunitoSans-SemiBold.woff2") format("woff2"), url("/static/fonts/NunitoSans-SemiBold.woff") format("woff"), url("/static/fonts/NunitoSans-SemiBold.ttf") format("truetype"), url("/static/fonts/NunitoSans-SemiBold.svg#NunitoSans-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("/static/fonts/NunitoSans-ExtraBold.eot");
  src: url("/static/fonts/NunitoSans-ExtraBold.eot?#iefix") format("embedded-opentype"), url("/static/fonts/NunitoSans-ExtraBold.woff") format("woff"), url("/static/fonts/NunitoSans-ExtraBold.ttf") format("truetype"), url("/static/fonts/NunitoSans-ExtraBold.svg#NunitoSans-ExtraBold") format("svg");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("/static/fonts/NunitoSans-Regular.eot");
  src: url("/static/fonts/NunitoSans-Regular.eot?#iefix") format("embedded-opentype"), url("/static/fonts/NunitoSans-Regular.woff2") format("woff2"), url("/static/fonts/NunitoSans-Regular.woff") format("woff"), url("/static/fonts/NunitoSans-Regular.ttf") format("truetype"), url("/static/fonts/NunitoSans-Regular.svg#NunitoSans-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("/static/fonts/NunitoSans-BoldItalic.eot");
  src: url("/static/fonts/NunitoSans-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/static/fonts/NunitoSans-BoldItalic.woff2") format("woff2"), url("/static/fonts/NunitoSans-BoldItalic.woff") format("woff"), url("/static/fonts/NunitoSans-BoldItalic.ttf") format("truetype"), url("/static/fonts/NunitoSans-BoldItalic.svg#NunitoSans-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("/static/fonts/NunitoSans-BlackItalic.eot");
  src: url("/static/fonts/NunitoSans-BlackItalic.eot?#iefix") format("embedded-opentype"), url("/static/fonts/NunitoSans-BlackItalic.woff2") format("woff2"), url("/static/fonts/NunitoSans-BlackItalic.woff") format("woff"), url("/static/fonts/NunitoSans-BlackItalic.ttf") format("truetype"), url("/static/fonts/NunitoSans-BlackItalic.svg#NunitoSans-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("/static/fonts/NunitoSans-SemiBoldItalic.eot");
  src: url("/static/fonts/NunitoSans-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("/static/fonts/NunitoSans-SemiBoldItalic.woff2") format("woff2"), url("/static/fonts/NunitoSans-SemiBoldItalic.woff") format("woff"), url("/static/fonts/NunitoSans-SemiBoldItalic.ttf") format("truetype"), url("/static/fonts/NunitoSans-SemiBoldItalic.svg#NunitoSans-SemiBoldItalic") format("svg");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("/static/fonts/NunitoSans-ExtraBoldItalic.eot");
  src: url("/static/fonts/NunitoSans-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("/static/fonts/NunitoSans-ExtraBoldItalic.woff2") format("woff2"), url("/static/fonts/NunitoSans-ExtraBoldItalic.woff") format("woff"), url("/static/fonts/NunitoSans-ExtraBoldItalic.ttf") format("truetype"), url("/static/fonts/NunitoSans-ExtraBoldItalic.svg#NunitoSans-ExtraBoldItalic") format("svg");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("/static/fonts/NunitoSans-LightItalic.eot");
  src: url("/static/fonts/NunitoSans-LightItalic.eot?#iefix") format("embedded-opentype"), url("/static/fonts/NunitoSans-LightItalic.woff2") format("woff2"), url("/static/fonts/NunitoSans-LightItalic.woff") format("woff"), url("/static/fonts/NunitoSans-LightItalic.ttf") format("truetype"), url("/static/fonts/NunitoSans-LightItalic.svg#NunitoSans-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic; }

/* Height */
.vh-10 {
  height: 10vh; }

.vh-15 {
  height: 15vh; }

.vh-20 {
  height: 20vh; }

.vh-25 {
  height: 25vh; }

.vh-30 {
  height: 30vh; }

.vh-35 {
  height: 35vh; }

.vh-40 {
  height: 40vh; }

.vh-45 {
  height: 45vh; }

.vh-50 {
  height: 50vh; }

.vh-55 {
  height: 55vh; }

.vh-60 {
  height: 60vh; }

.vh-65 {
  height: 65vh; }

.vh-70 {
  height: 70vh; }

.vh-75 {
  height: 75vh; }

.vh-80 {
  height: 80vh; }

.vh-85 {
  height: 85vh; }

.vh-90 {
  height: 90vh; }

.vh-95 {
  height: 95vh; }

.vh-100 {
  height: 100vh; }

/* Width */
.vw-10 {
  width: 10vw; }

.vw-15 {
  width: 15vw; }

.vw-20 {
  width: 20vw; }

.vw-25 {
  width: 25vw; }

.vw-30 {
  width: 30vw; }

.vw-35 {
  width: 35vw; }

.vw-40 {
  width: 40vw; }

.vw-45 {
  width: 45vw; }

.vw-50 {
  width: 50vw; }

.vw-55 {
  width: 55vw; }

.vw-60 {
  width: 60vw; }

.vw-65 {
  width: 65vw; }

.vw-70 {
  width: 70vw; }

.vw-75 {
  width: 75vw; }

.vw-80 {
  width: 80vw; }

.vw-85 {
  width: 85vw; }

.vw-90 {
  width: 90vw; }

.vw-95 {
  width: 95vw; }

.vw-100 {
  width: 100vw; }

/* General CSS Style */
body {
  font-family: 'Nunito Sans' !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-stretch: normal !important; }

.bg {
  /* Full height */
  height: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

@media (max-width: 991.99px) {
  .home.bg {
    max-height: 320px; } }

@media (max-width: 416px) {
  .home.bg {
    background-position: -648px 25px;
    max-height: 376px; } }

.bg-dark {
  background-color: var(--dark) !important; }

@media (min-width: 768px) {
  .container {
    max-width: 882px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

@media (max-width: 992px) {
  .container-fluid {
    padding: 50px; } }

.text-success {
  color: var(--macaroni-and-cheese) !important; }

.text-danger {
  color: var(--slate) !important; }

label {
  margin-bottom: 0px; }

ul {
  font-size: 16.7px; }

p, li {
  font-size: 16.7px;
  color: #000000;
  font-weight: 400; }

.black {
  color: #000000; }

a.black:hover {
  color: var(--dark-grey-blue-two); }

@media (min-width: 1200px) {
  .content-section .container {
    padding-right: 400px; } }

h2, h3, h4 {
  margin-top: 1.5rem; }
  h2:first-child, h3:first-child, h4:first-child {
    margin-top: 0; }

p:last-child {
  margin-bottom: 0px; }

.indent {
  padding-left: 40px; }

table {
  font-size: 13.3px;
  border: solid 1px var(--duck-egg-blue); }
  table thead {
    background-color: var(--ice-blue);
    padding: 10px 15px;
    font-weight: 700; }
  table tbody tr:nth-of-type(even) {
    background-color: #f1f7f9 !important; }
  table tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0) !important; }
  table th, table td {
    border-top: 0.01px solid transparent !important;
    padding: 19px; }

.no-break {
  display: inline-block; }

.no-background-section .container-fluid {
  padding-top: 0px !important; }

.btn-secondary {
  border-color: var(--ice-blue);
  color: var(--dark) !important;
  background-color: var(--ice-blue);
  padding: 10px 25px 8px;
  margin-right: 10px;
  font-size: 13.3px;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-transform: uppercase; }
  .btn-secondary:hover, .btn-secondary:focus {
    background-color: var(--light-blue-grey);
    border: 1px solid var(--greyblue);
    color: var(--dark) !important;
    text-decoration: none !important; }

.btn-orange {
  font-size: 13.3px;
  font-weight: 700;
  text-decoration: none !important;
  padding: 10px 25px 8px;
  color: var(--dark);
  border-radius: 3px;
  background-color: var(--macaroni-and-cheese);
  text-align: center;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase; }
  .btn-orange:hover, .btn-orange:focus {
    background-color: var(--sun-yellow);
    border: 1px solid var(--macaroni-and-cheese);
    text-decoration: none !important; }

.btn.btn-neutral {
  min-width: 151px;
  border-radius: 3px;
  background-color: var(--ice-blue) !important;
  font-size: 13.3px;
  font-weight: 600;
  text-transform: uppercase;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: center;
  color: var(--dark);
  box-shadow: 0 1px 2px -1px rgba(255, 255, 255, 0) inset, 0px -2px 0px 0px rgba(0, 0, 0, 0) inset;
  padding: 11px 16px; }
  .btn.btn-neutral span {
    display: none; }
  .btn.btn-neutral:hover {
    background-color: var(--light-blue-grey) !important; }

.btn-lg {
  font-size: 16.7px !important;
  font-weight: 700 !important;
  padding: 20px 30px 18px !important; }

.btn-wide {
  min-width: 209px; }

.btn-icon {
  text-align: left;
  padding-left: 14px;
  padding-right: 12px; }
  .btn-icon img {
    float: right;
    margin-bottom: 0;
    height: 24px;
    position: relative;
    top: -5px; }

.label {
  padding: 0 0 6px;
  text-transform: uppercase;
  border-bottom: 2px solid var(--macaroni-and-cheese); }

.card-transparent {
  background: transparent; }

/* Custom HEADER CSS Style */
.bg-primary {
  background-color: var(--clear-blue) !important; }

.navbar-dark {
  z-index: 2; }
  .navbar-dark .navbar-nav .nav-link {
    color: var(--white) !important; }
  .navbar-dark .navbar-brand {
    color: var(--white);
    font-size: 29px;
    line-height: 1.15;
    margin-right: 40px;
    margin-left: 40px;
    padding: 29px 0; }
    .navbar-dark .navbar-brand img {
      vertical-align: bottom;
      width: 245px;
      height: 41.4531px; }

.dropdown-menu {
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
  right: auto;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0); }

/*.collapsing {
    -webkit-transition: .2m;
    transition: none;
    display: none;
}*/
.navbar-expand-lg .form-inline {
  color: var(--white);
  padding: 0;
  margin: auto 45px;
  max-width: 400px;
  padding-top: 2px; }
  .navbar-expand-lg .form-inline .form-control {
    display: inline-block;
    vertical-align: middle;
    background-color: var(--clear-blue);
    border-top: 0 transparent;
    border-left: 0 transparent;
    border-right: 0 transparent;
    border-bottom: 1px solid var(--white);
    border-radius: 0;
    padding: 24px 0;
    color: var(--white); }
  .navbar-expand-lg .form-inline .fas, .navbar-expand-lg .form-inline .fa {
    color: var(--white);
    font-size: 20px;
    font-weight: 600;
    margin-top: 6px;
    margin-left: 7px; }
  .navbar-expand-lg .form-inline .btn-link {
    border-top: 0 transparent;
    border-left: 0 transparent;
    border-right: 0 transparent;
    border-bottom: 1px solid var(--white);
    border-radius: 0;
    padding: .375rem 12px .375rem 18px; }
  .navbar-expand-lg .form-inline .form-control::placeholder {
    color: var(--white);
    opacity: 1; }
  .navbar-expand-lg .form-inline .form-control:focus {
    color: var(--white);
    background-color: transparent;
    border-color: var(--white);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0); }

.navbar-expand-lg .navbar-nav {
  /*.nav-link {
      &:hover {
        background-color: var(--sky-blue);
      }
    }*/
  width: 100%; }
  .navbar-expand-lg .navbar-nav .nav-item.show .nav-link {
    background-color: var(--sky-blue); }

.navbar-dark .navbar-toggler-icon {
  /*background-image:url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");*/
  background-image: none !important; }

.navbar-expand-lg .navbar-nav .nav-link {
  font-size: 1em;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  white-space: nowrap; }

.navbar-dark .navbar-nav .search-form {
  display: none; }

.navbar-links-custom .dropdown ul {
  text-align: center; }
  .navbar-links-custom .dropdown ul li {
    float: none;
    display: inline-block; }

/* Medium devices (tablets, 768px and up) */
@media (max-width: 991.98px) {
  .container-fluid {
    padding-top: 20px;
    padding-bottom: 20px; }
  .navbar-expand-lg .form-inline {
    color: var(--white);
    padding: 0;
    margin: 0;
    max-width: 100%; }
    .navbar-expand-lg .form-inline.desktop {
      display: none; }
  .navbar-brand.menu-expanded {
    display: none; }
  .navbar-dark .navbar-nav .nav-link {
    padding: 1em 0 0.8em 25px;
    border-bottom: solid 1px white;
    text-align: left; }
  .navbar-dark .navbar-nav .search-form {
    display: list-item; }
  .navbar-expand-lg .form-inline .form-control {
    padding-left: 6px; }
  .navbar-expand-lg .form-inline .btn-link {
    padding: .375rem 12px .375rem 18px; }
  .navbar-dark .navbar-toggler {
    color: white !important;
    border: 0 transparent;
    padding-right: 1em !important; }
    .navbar-dark .navbar-toggler object {
      -webkit-filter: invert(100%);
      width: 44px;
      height: 44px;
      pointer-events: none; }
    .navbar-dark .navbar-toggler:focus {
      color: white !important;
      border: 0 transparent; }
  .dropdown-toggle::after {
    display: none; }
  .navbar {
    padding: 0 !important; }
  .navbar-brand {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-right: 0 !important;
    margin-left: 1rem !important; }
  .dropdown-menu {
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    background-color: var(--clear-blue);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 0;
    border-bottom: solid 1px var(--white); }
  .dropdown-item {
    color: var(--white);
    font-size: 1em;
    min-width: 100%;
    height: 38px;
    padding: .25rem 1.5rem !important;
    border-radius: 0; }
    .dropdown-item:hover, .dropdown-item:focus {
      background-color: var(--sky-blue);
      color: var(--white); } }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-expand-lg {
    width: 100%;
    padding: 0; }
    .navbar-expand-lg .container-fluid {
      width: 100%;
      padding-right: 0;
      padding-left: 0;
      margin-right: 0;
      margin-left: 0; }
    .navbar-expand-lg .navbar-nav .nav-link {
      height: 100px;
      padding-top: 40px; }
    .navbar-expand-lg .navbar-nav .nav-item {
      border-left: solid 1px var(--carolina-blue); }
      .navbar-expand-lg .navbar-nav .nav-item.last {
        border-right: solid 1px var(--carolina-blue); }
  .dropdown:hover .dropdown-menu, .btn-group:hover .dropdown-menu {
    display: block; }
  .dropdown-menu {
    margin-top: 0; }
  .dropdown-toggle {
    margin-bottom: 2px; }
  .navbar .dropdown-toggle, .nav-tabs .dropdown-toggle {
    margin-bottom: 0; }
  .dropdown-menu:before {
    content: "";
    top: -11px;
    left: calc(45% - 2px);
    position: absolute;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 11px solid var(--duck-egg-blue); }
  .dropdown-menu:after {
    content: "";
    top: -10px;
    left: 45%;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--white); }
  .dropdown-toggle::after {
    display: none; } }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .navbar-expand-lg {
    width: 100%;
    padding: 0; }
    .navbar-expand-lg .container-fluid {
      width: 100%;
      padding-right: 0;
      padding-left: 0;
      margin-right: 0;
      margin-left: 0; }
    .navbar-expand-lg .navbar-nav .nav-link {
      height: 100px;
      padding-top: 40px; }
    .navbar-expand-lg .navbar-nav .nav-item {
      border-left: solid 1px var(--carolina-blue); }
      .navbar-expand-lg .navbar-nav .nav-item.last {
        border-right: solid 1px var(--carolina-blue); }
  .dropdown:hover .dropdown-menu, .btn-group:hover .dropdown-menu {
    display: block; }
  .dropdown-toggle {
    margin-bottom: 2px; }
  .navbar .dropdown-toggle, .nav-tabs .dropdown-toggle {
    margin-bottom: 0; }
  .dropdown-menu:before {
    content: "";
    top: -11px;
    left: calc(45% - 2px);
    position: absolute;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 11px solid var(--duck-egg-blue); }
  .dropdown-menu:after {
    content: "";
    top: -10px;
    left: 45%;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--white); }
  .dropdown-toggle::after {
    display: none; } }

/* MENU SPACING */
@media (min-width: 992px) and (max-width: 1049.98px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 8px;
    padding-right: 8px; }
  .navbar-dark .navbar-brand {
    margin-right: 15px;
    margin-left: 15px; }
  .navbar-expand-lg .form-inline {
    padding-top: 2px;
    margin: auto 15px; }
  .dropdown-menu {
    margin-top: -8px; } }

@media (min-width: 1050px) and (max-width: 1199.98px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 15px;
    padding-right: 15px; }
  .navbar-dark .navbar-brand {
    margin-right: 25px;
    margin-left: 25px; }
  .navbar-expand-lg .form-inline {
    padding-top: 2px;
    margin: auto 25px; }
  .dropdown-menu {
    margin-top: -8px; } }

@media (min-width: 1200px) and (max-width: 1440px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 25px;
    padding-right: 25px; }
  .dropdown-menu {
    margin-top: -8px; } }

@media (min-width: 1441px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 45px;
    padding-right: 45px; }
  .dropdown-menu {
    margin-top: -8px; } }

/* MENU */
@media (min-width: 992px) {
  .mobile-dropdown {
    display: none; }
  .dropdown-item, .dropdown-item:visited {
    color: var(--clear-blue);
    font-size: 1em;
    min-width: 125px;
    height: 38px;
    padding: .25rem 18px !important;
    border-radius: 3px;
    line-height: 1.95;
    letter-spacing: normal; }
    .dropdown-item:focus, .dropdown-item:hover, .dropdown-item:visited:focus, .dropdown-item:visited:hover {
      background-color: var(--pale-grey);
      color: var(--clear-blue); }
  .navbar-expand-lg .dropdown-menu {
    box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16);
    border: solid 1px var(--duck-egg-blue);
    background-color: var(--white); } }

@media (max-width: 991px) {
  .navbar-expand-lg .dropdown-menu {
    box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0);
    border: solid 1px #d7dbdc00;
    background-color: transparent;
    border-bottom: solid 1px #ffffff; }
  .navbar-nav .nav-item.dropdown .mobile-dropdown .open {
    display: none; }
  .navbar-nav .nav-item.dropdown .mobile-dropdown .closed {
    display: block; }
  .navbar-nav .nav-item.dropdown.show .mobile-dropdown .open {
    display: block; }
  .navbar-nav .nav-item.dropdown.show .mobile-dropdown .closed {
    display: none; }
  .mobile-dropdown {
    display: block;
    top: 0;
    position: absolute;
    right: 0;
    z-index: 100;
    height: 53px;
    width: 80px;
    padding: 10px 20px;
    pointer-events: none; }
    .mobile-dropdown .icon {
      float: right; }
  .navbar-toggler {
    padding: 0 0 0 20px; }
  body {
    width: 100vw;
    height: 60vh;
    animation: bugfix infinite 1s;
    -webkit-animation: bugfix infinite 1s; }
  @keyframes bugfix {
    from {
      padding: 0; }
    to {
      padding: 0; } }
  @-webkit-keyframes bugfix {
    from {
      padding: 0; }
    to {
      padding: 0; } }
  #overlay-button {
    position: absolute;
    right: 2em;
    top: 3em;
    padding: 26px 11px;
    z-index: 5;
    cursor: pointer;
    user-select: none; }
  #navbar-toggler-ico span {
    height: 4px;
    width: 35px;
    border-radius: 2px;
    background-color: white;
    position: relative;
    display: block;
    transition: all .8s ease-in-out; }
    #navbar-toggler-ico span:before {
      top: -10px;
      visibility: visible; }
    #navbar-toggler-ico span:after {
      top: 10px; }
    #navbar-toggler-ico span:before, #navbar-toggler-ico span:after {
      height: 4px;
      width: 35px;
      right: 0;
      border-radius: 2px;
      background-color: white;
      position: absolute;
      content: "";
      transition: all .2s ease-in-out; }
  #navbar-toggler-ico:hover span {
    background: #ffffff; }
    #navbar-toggler-ico:hover span:before, #navbar-toggler-ico:hover span:after {
      background: #ffffff; }
  .navbar-toggler[aria-expanded="true"] {
    z-index: 999999999;
    position: fixed;
    top: 20px;
    height: 35px;
    right: 0; }
    .navbar-toggler[aria-expanded="true"] #navbar-toggler-ico:hover span {
      background: transparent; }
    .navbar-toggler[aria-expanded="true"] #navbar-toggler-ico span {
      background: transparent; }
      .navbar-toggler[aria-expanded="true"] #navbar-toggler-ico span:before {
        transform: rotate(45deg) translate(7px, 7px);
        opacity: 1; }
      .navbar-toggler[aria-expanded="true"] #navbar-toggler-ico span:after {
        transform: rotate(-45deg) translate(7px, -7px); }
  .navbar-brand.menu-expanded {
    z-index: 999999999;
    position: fixed;
    top: 10px;
    left: 0; }
  .navbar-collapse {
    height: 100vh;
    width: 100vw;
    background: var(--clear-blue);
    z-index: 9999999;
    position: fixed;
    top: 0;
    left: 0; }
  .navbar-nav .dropdown-menu {
    position: relative;
    float: none; } }

@media (max-width: 991px) {
  .navbar-expand-lg .form-inline .form-control {
    padding: 44px 0 43px; } }

/* Custom FOOTER CSS Style */
.container-fluid.footer {
  padding: 0;
  margin: 0; }

footer.top .form-group {
  width: 100%;
  height: 45px;
  margin-bottom: 12px;
  border-radius: 3px; }

footer.top .form-control {
  width: 100%;
  height: 45px;
  font-size: 15px; }

footer.top .form-group .btn {
  width: 100%;
  font-size: 1em;
  line-height: 1.33;
  letter-spacing: 0.5px;
  box-shadow: none; }

footer .mb-2 {
  font-size: 20.7px; }

footer .mb-1 {
  font-size: 1em; }

footer.bottom {
  border-top: solid 1px rgba(215, 219, 220, 0.34);
  font-size: 1em;
  line-height: 1.33; }
  footer.bottom .list-inline {
    opacity: 0.65; }
  footer.bottom img {
    vertical-align: bottom;
    height: 28px;
    margin-bottom: -3px;
    opacity: 1; }

/* Medium devices (tablets, 768px and up) */
@media (max-width: 991.98px) {
  footer.top {
    padding: 21px 45px 45px 45px; }
  footer .mb-2 {
    margin-top: 40px; }
  footer.bottom {
    padding: 30px 15px 45px 15px; }
    footer.bottom .text-inline {
      text-align: center !important; } }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  footer.top {
    padding: 60px 100px 44px; }
  footer.bottom {
    padding: 30px 100px; } }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  footer.top {
    padding: 60px 100px 44px; }
  footer.bottom {
    padding: 30px 100px; } }

.sidebar {
  padding-left: 40px;
  /*.card {
    margin-top: 25px;
  }*/ }
  .sidebar .title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 25px;
    margin-top: -6px; }
  .sidebar .card {
    margin-bottom: 25px;
    border: solid 1px var(--duck-egg-blue);
    border-radius: 3px; }

@media (max-width: 992px) {
  .sidebar {
    padding-left: 15px;
    padding-top: 40px; } }

@media (max-width: 668px) {
  .sidebar {
    display: none; } }

.sidebar-section {
  margin-bottom: 45px; }

.sidebar-links {
  border-top: solid 1px var(--duck-egg-blue);
  padding-top: 22px; }

.sidebar .sidebar-rating-card img {
  height: 17px; }

.sidebar .sidebar-rating-card h5 {
  font-size: 20.8px;
  margin-bottom: 0px; }

.sidebar .sidebar-rating-card .rating-stars {
  margin-top: 6px; }

.sidebar .sidebar-rating-card .nonprofit-impact-line {
  margin-bottom: 0px; }

.sidebar .sidebar-rating-card .separator {
  padding: 0 10px; }

/* Custom Splash CSS Style */
section.home-section {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  section.home-section.bg-blue {
    background: var(--clear-blue); }
    section.home-section.bg-blue a {
      color: var(--sun-yellow) !important; }
      section.home-section.bg-blue a:hover {
        color: var(--maize) !important; }
  section.home-section.bg-black {
    background: var(--dark); }
  section.home-section.bg-white {
    background: var(--white); }
  section.home-section .overlay {
    overflow: hidden;
    height: 100%;
    z-index: 2; }
    section.home-section .overlay.blue {
      background: rgba(40, 131, 255, 0.85); }
    section.home-section .overlay.black {
      background: rgba(32, 32, 26, 0.85); }
    section.home-section .overlay.white {
      background: rgba(255, 255, 255, 0.85); }
  section.home-section .container-fluid {
    padding: 8%; }
  section.home-section .icon {
    display: block;
    height: 36px;
    width: 36px;
    margin-bottom: 28px; }
  section.home-section .section-title {
    font-size: 40.7px;
    font-weight: 700;
    line-height: 1.33; }
  section.home-section .section-description {
    font-size: 40.7px;
    font-weight: 300;
    line-height: 1.33;
    width: 80%; }
  section.home-section .section-description-small {
    margin-bottom: 48px;
    width: 80%; }
  section.home-section .black {
    color: var(--black); }
  section.home-section .white {
    color: var(--white); }
    section.home-section .white.icon {
      filter: invert(100%) hue-rotate(0deg) saturate(0%) brightness(1000%) contrast(100%); }

@media (max-width: 416px) {
  section.home-section .section-title {
    font-size: 32.5px; }
  section.home-section .section-description {
    font-size: 26px; }
    section.home-section .section-description:last-child {
      margin-bottom: 0px; } }

section.media-visibility {
  background-color: var(--dark) !important;
  justify-items: center;
  opacity: 1;
  font-size: 16.7px;
  font-weight: 400;
  line-height: 1.33;
  color: var(--white); }
  section.media-visibility .container-fluid {
    padding: 90px 100px; }
  section.media-visibility img {
    height: 30px; }
  section.media-visibility h6 {
    margin: 0 !important; }
  section.media-visibility .media-logos {
    display: flex;
    justify-content: space-between;
    align-items: center; }

#carouselLocalGivingTablet,
#carouselLocalGiving {
  margin: 71px auto 0 auto; }

#carouselLocalGivingMobile {
  margin: 48px auto 0 auto; }

#pageLocalGiving,
#carouselLocalGivingMobile,
#carouselLocalGivingTablet,
#carouselLocalGiving {
  max-width: 1402px;
  padding: 0; }
  #pageLocalGiving .carousel-control-prev,
  #carouselLocalGivingMobile .carousel-control-prev,
  #carouselLocalGivingTablet .carousel-control-prev,
  #carouselLocalGiving .carousel-control-prev {
    left: 0;
    opacity: 1;
    z-index: 3;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer !important; }
    #pageLocalGiving .carousel-control-prev:hover, #pageLocalGiving .carousel-control-prev:focus,
    #carouselLocalGivingMobile .carousel-control-prev:hover,
    #carouselLocalGivingMobile .carousel-control-prev:focus,
    #carouselLocalGivingTablet .carousel-control-prev:hover,
    #carouselLocalGivingTablet .carousel-control-prev:focus,
    #carouselLocalGiving .carousel-control-prev:hover,
    #carouselLocalGiving .carousel-control-prev:focus {
      opacity: 1; }
  #pageLocalGiving .carousel-control-next,
  #carouselLocalGivingMobile .carousel-control-next,
  #carouselLocalGivingTablet .carousel-control-next,
  #carouselLocalGiving .carousel-control-next {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer !important;
    right: -40px;
    opacity: 1;
    z-index: 3; }
    #pageLocalGiving .carousel-control-next:hover, #pageLocalGiving .carousel-control-next:focus,
    #carouselLocalGivingMobile .carousel-control-next:hover,
    #carouselLocalGivingMobile .carousel-control-next:focus,
    #carouselLocalGivingTablet .carousel-control-next:hover,
    #carouselLocalGivingTablet .carousel-control-next:focus,
    #carouselLocalGiving .carousel-control-next:hover,
    #carouselLocalGiving .carousel-control-next:focus {
      opacity: 1; }
  #pageLocalGiving .carousel-control-prev-icon,
  #pageLocalGiving .carousel-control-next-icon,
  #carouselLocalGivingMobile .carousel-control-prev-icon,
  #carouselLocalGivingMobile .carousel-control-next-icon,
  #carouselLocalGivingTablet .carousel-control-prev-icon,
  #carouselLocalGivingTablet .carousel-control-next-icon,
  #carouselLocalGiving .carousel-control-prev-icon,
  #carouselLocalGiving .carousel-control-next-icon {
    border-radius: 50%;
    object-fit: contain;
    background-color: var(--white);
    cursor: pointer !important; }
  #pageLocalGiving .card-body,
  #carouselLocalGivingMobile .card-body,
  #carouselLocalGivingTablet .card-body,
  #carouselLocalGiving .card-body {
    /*max-height: 441px;*/
    border-radius: 6px;
    position: relative;
    padding: 0;
    border: 0 solid rgba(0, 0, 0, 0); }
    #pageLocalGiving .card-body .title,
    #carouselLocalGivingMobile .card-body .title,
    #carouselLocalGivingTablet .card-body .title,
    #carouselLocalGiving .card-body .title {
      font-size: 26px;
      font-weight: 400;
      line-height: 1.33;
      text-align: center;
      position: absolute;
      top: 39px;
      z-index: 3;
      width: 100%; }
    #pageLocalGiving .card-body .find-nonprofit,
    #carouselLocalGivingMobile .card-body .find-nonprofit,
    #carouselLocalGivingTablet .card-body .find-nonprofit,
    #carouselLocalGiving .card-body .find-nonprofit {
      z-index: 3;
      font-size: 16.7px;
      font-weight: 800;
      padding: 20px 10px;
      letter-spacing: 0.5px;
      text-align: center;
      color: var(--dark);
      height: 60px;
      border-radius: 3px;
      background-color: var(--macaroni-and-cheese);
      margin: auto;
      width: 80%;
      position: absolute;
      bottom: 29px;
      left: 10%; }
      #pageLocalGiving .card-body .find-nonprofit:hover, #pageLocalGiving .card-body .find-nonprofit:focus,
      #carouselLocalGivingMobile .card-body .find-nonprofit:hover,
      #carouselLocalGivingMobile .card-body .find-nonprofit:focus,
      #carouselLocalGivingTablet .card-body .find-nonprofit:hover,
      #carouselLocalGivingTablet .card-body .find-nonprofit:focus,
      #carouselLocalGiving .card-body .find-nonprofit:hover,
      #carouselLocalGiving .card-body .find-nonprofit:focus {
        color: var(--dark);
        text-decoration: none; }
    #pageLocalGiving .card-body .overlay,
    #carouselLocalGivingMobile .card-body .overlay,
    #carouselLocalGivingTablet .card-body .overlay,
    #carouselLocalGiving .card-body .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 40%;
      background-color: transparent !important;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)); }
    #pageLocalGiving .card-body img,
    #carouselLocalGivingMobile .card-body img,
    #carouselLocalGivingTablet .card-body img,
    #carouselLocalGiving .card-body img {
      z-index: 1;
      width: 100%;
      border-radius: 6px; }

.splash-section {
  background-color: #ebf3ff !important;
  height: 65vh;
  min-height: 600px; }

.splash-sub-section {
  background-color: #f5f4f3 !important;
  min-height: 400px; }

.splash-title {
  margin-bottom: 20px; }

.splash-subtitle {
  font-size: 20.8px;
  margin-bottom: 26px;
  color: white; }

.home {
  opacity: .85;
  padding: 60px 30px 100px;
  background-position-y: -40px;
  background-position-x: 0; }
  .home h1 {
    /*font-size: 63.5px;*/
    color: white;
    font-size: 44px;
    font-weight: 700;
    line-height: 1.03;
    letter-spacing: -0.33px; }
  .home h5 {
    font-size: 26px;
    font-weight: 400;
    line-height: 1.33; }
  .home .call-to-action {
    font-size: 16.7px;
    font-weight: 700;
    padding: 20px 30px;
    letter-spacing: 0.5px;
    text-align: left;
    color: var(--dark);
    border-radius: 3px;
    background-color: var(--macaroni-and-cheese); }
    .home .call-to-action:hover, .home .call-to-action:focus {
      background-color: var(--sun-yellow);
      text-decoration: none; }
  .home .call-to-action-mobile {
    display: none; }
  .home .container {
    width: 980px;
    padding: 0px; }

@media (max-width: 991.99px) {
  .home {
    padding: 50px 30px; }
  h5 {
    display: none; } }

@media (max-width: 416px) {
  .home.splash-landing {
    padding: 40px 0 25px;
    background-image: none !important;
    background-color: #f5f4f3 !important;
    height: auto; }
    .home.splash-landing h1 {
      font-size: 33px;
      color: var(--black); }
    .home.splash-landing .splash-subtitle {
      color: var(--black); }
    .home.splash-landing .call-to-action {
      display: none; }
    .home.splash-landing .call-to-action-mobile {
      display: inline-block;
      font-size: 16.7px;
      font-weight: 700;
      padding: 16px 16px;
      letter-spacing: 0.5px;
      text-align: left;
      color: var(--dark);
      border-radius: 3px;
      background-color: var(--macaroni-and-cheese); }
      .home.splash-landing .call-to-action-mobile:hover, .home.splash-landing .call-to-action-mobile:focus {
        background-color: var(--sun-yellow);
        text-decoration: none; }
    .home.splash-landing .container {
      padding: 0 20px; } }

.give-confidence,
.local-giving {
  color: var(--white); }

.give-confidence {
  color: var(--white); }

.overlay {
  background: rgba(40, 131, 255, 0.85);
  overflow: hidden;
  height: 100%;
  z-index: 2; }

.local-giving .local-giving-text, .give-confidence .give-confidence-text {
  padding: 100px;
  color: var(--white); }

.local-giving .local-giving h2, .give-confidence .give-confidence-text h2 {
  font-size: 40.7px;
  font-weight: 800; }

.local-giving .local-giving-text p, .give-confidence .give-confidence-text p {
  font-size: 40.7px;
  line-height: 1.33;
  font-weight: 400; }

.local-giving .local-giving-text {
  color: black; }

.cities {
  padding: 90px; }

.as-seen-section {
  height: 212px;
  background-color: var(--dark) !important;
  justify-items: center;
  padding: 85px 40px;
  opacity: 1; }

.as-seen-in {
  text-align: left; }

@media (max-width: 991px) {
  .as-seen-in {
    text-align: center; } }

.calculator-box {
  margin: -45px auto 0 auto;
  max-width: 980px;
  display: block;
  border-radius: 3px;
  box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16);
  border: solid 1px var(--duck-egg-blue);
  background-color: var(--white);
  font-size: 26px;
  font-weight: 600;
  line-height: 1.33;
  padding: 25px 30px; }

.calculator-box-left-items {
  margin-right: 15px;
  display: inline-block;
  margin-bottom: 24px; }

.calculator-box-right-items {
  display: inline-block; }
  .calculator-box-right-items .qty-plus {
    margin-right: 0; }

.select-container {
  position: relative;
  display: inline-flex;
  background-color: rgba(248, 248, 248, 0);
  border-bottom: 2px solid var(--macaroni-and-cheese);
  cursor: pointer; }
  .select-container .fa.fa-caret-down {
    font-size: 15px;
    padding-top: 10px;
    margin-right: 4px;
    margin-left: 8px; }

#top-list-list {
  /*border-color: rgba(166, 166, 166, 0);
  appearance: none;
  transition: 0.2s;*/
  padding-left: 0;
  padding-right: 15px;
  margin-left: 0;
  background-color: rgba(248, 248, 248, 0);
  overflow: hidden;
  min-width: 30px;
  outline: none;
  border: none;
  appearance: menulist;
  /*margin-left: -8px;*/
  font-size: 26px;
  font-weight: 600;
  cursor: pointer;
  margin-left: -3px; }

.top-list-dropdown-icon {
  display: none; }

@media (max-width: 991.99px) {
  .top-list-dropdown-icon {
    display: block;
    height: 20px;
    position: absolute !important;
    top: 5px !important;
    right: 0 !important;
    color: var(--dark);
    /* z-index: -1; */
    pointer-events: none;
    width: 20px; } }

.slider-card {
  border: solid 0 var(--duck-egg-blue);
  font-size: 32.5px;
  line-height: 1.3;
  font-weight: 400;
  color: #000000; }

#splash-carousel {
  margin: 28.2px auto 48.2px auto;
  max-width: 980px;
  padding: 40px 45px;
  border-radius: 3px;
  background-color: var(--white);
  border: solid 1px var(--duck-egg-blue);
  box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16); }

@media (max-width: 416px) {
  .splash-landing .splash-sub-section {
    display: none !important; } }

@media (max-width: 416px) {
  #splash-carousel {
    padding: 0px;
    margin: 20px 0; }
  .calculator-box {
    padding: 30px;
    min-height: 244px; }
  .carousel-item .card-body {
    padding: 30px; }
  .slider-card {
    font-size: 20.8px;
    min-height: 265px; }
  .nonprofit-summary .summary-geography {
    display: block;
    white-space: normal;
    margin-top: 4px; }
  .nonprofit-summary .separator {
    display: none; } }

.carousel-control {
  position: absolute;
  top: 50%;
  /* pushes the icon in the middle of the height */
  z-index: 5;
  display: inline-block; }

.carousel-control-prev-icon {
  background-image: url("https://s3.amazonaws.com/impactmatters-production/streamline-icons/svg/01-Interface Essential/50-Navigate/navigation-left-circle-1_1.svg"); }

.carousel-control-next-icon {
  background-image: url("https://s3.amazonaws.com/impactmatters-production/streamline-icons/svg/01-Interface Essential/50-Navigate/navigation-right-circle-1.svg");
  display: inline-block;
  width: 40px;
  height: 40px; }

.carousel-control-prev-icon {
  display: inline-block;
  width: 40px;
  height: 40px; }

.carousel-control-next {
  justify-content: flex-end; }

.carousel-control-prev {
  justify-content: flex-start; }

@media (max-width: 416px) {
  .carousel-control-prev:before, .carousel-control-next:before {
    content: "";
    background-color: white !important;
    font-weight: bold;
    height: 40px;
    width: 39px;
    border-radius: 100px;
    position: absolute;
    z-index: 10;
    border: solid 1px var(--duck-egg-blue);
    box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16); }
  .carousel-control-prev:before {
    margin-left: 1px; }
  .carousel-control-next:before {
    margin-left: -1px; }
  .carousel-control-prev-icon, .carousel-control-next-icon {
    z-index: 20; } }

.carousel .nonprofit-block {
  margin: 0; }

.splash-sub-section .nonprofit-card {
  margin-top: 22px; }

.nonprofit-card {
  display: flex;
  width: 100%; }
  .nonprofit-card .logo {
    width: 70px;
    height: 70px;
    border-radius: 3px;
    border: solid 1px var(--duck-egg-blue);
    background-color: var(--white); }

.splash-sub-section {
  /* margin: 0 23px; */ }

.nonprofit-order-block {
  margin-right: 12px; }
  .nonprofit-order-block .nonprofit-order {
    background-color: var(--dark);
    color: white;
    font-weight: bold;
    border-radius: 100px;
    width: 24px;
    height: 24px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center; }

.nonprofit-block {
  font-weight: 600;
  line-height: 1.1;
  margin: 0 23px 0 0; }
  .nonprofit-block p {
    margin-bottom: 0; }
  .nonprofit-block .title {
    font-size: 20.8px;
    margin-bottom: 4px; }
  .nonprofit-block .address {
    font-size: 13.3px; }
  .nonprofit-block .stars img {
    width: 100px;
    height: 19px !important; }
  .nonprofit-block h4 {
    margin-top: 0px; }

.section-description.top-list-section {
  margin-bottom: 48px; }

.top-lists-cards a:hover, .top-lists-cards a:focus {
  text-decoration: none; }

.top-lists-cards .card {
  min-height: 315px;
  width: 100%;
  border-radius: 5px;
  border: solid 1px var(--duck-egg-blue);
  background-color: var(--white);
  margin: 0 26px 22px 0;
  padding: 82px 20px 40px 20px;
  text-align: center;
  color: var(--dark); }
  .top-lists-cards .card .icon {
    font-size: 36px;
    margin: 0 auto 28px; }
  .top-lists-cards .card.crowded {
    padding: 42px 20px 40px 20px;
    vertical-align: middle; }
  .top-lists-cards .card.no-bottom-margin {
    margin-bottom: 0px; }
  .top-lists-cards .card:hover, .top-lists-cards .card:focus {
    transition: 0.5s;
    /*margin: 0 26px 0 0;*/
    text-decoration: none;
    border-radius: 5px;
    color: var(--white);
    box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16);
    border: solid 1px var(--duck-egg-blue);
    background-color: var(--clear-blue); }
    .top-lists-cards .card:hover .icon, .top-lists-cards .card:focus .icon {
      font-size: 36px;
      color: var(--white);
      transition: 0.5s;
      filter: invert(100%); }
    .top-lists-cards .card:hover object, .top-lists-cards .card:focus object {
      transition: 1s;
      filter: invert(100%) hue-rotate(0deg) saturate(0%) brightness(1000%) contrast(100%); }
  .top-lists-cards .card object.icon {
    width: 38px;
    height: 36px;
    object-fit: contain;
    margin: 0 auto 35px auto; }
  .top-lists-cards .card .title {
    min-height: 40.8px;
    font-size: 32.5px;
    font-weight: 700;
    line-height: 1.34;
    text-align: center;
    margin-bottom: 10px; }
  .top-lists-cards .card .description {
    max-width: 100%;
    min-height: 71.2px;
    font-weight: 400;
    font-size: 20.8px;
    line-height: 1.33;
    text-align: center; }

.top-lists-cards .col-lg-4:last-child .card {
  margin-bottom: 0px; }

@media (max-width: 992px) {
  .top-lists-cards .card.no-bottom-margin {
    margin-bottom: 22px; }
  .media-visibility .col-md-12 {
    margin-bottom: 20px; }
    .media-visibility .col-md-12:last-child {
      margin-bottom: 0; } }

.analyze-nonprofits a, .analyze-nonprofits a:hover, .analyze-nonprofits a:active {
  text-decoration: none; }

.analyze-nonprofits .container .icon {
  transition: .12s;
  width: 36px;
  height: 36px;
  filter: invert(100%) hue-rotate(0deg) saturate(0%) brightness(1000%) contrast(100%); }

.analyze-nonprofits .container .nonprofit-analysis .chapter {
  width: 100%;
  height: 54px;
  font-size: 32.5px;
  font-weight: 400;
  line-height: 1.34;
  color: var(--white);
  padding-right: -131px; }
  .analyze-nonprofits .container .nonprofit-analysis .chapter .btn {
    width: 131px;
    height: 40px;
    border-radius: 3px;
    background-color: var(--carolina-blue);
    float: right; }
    .analyze-nonprofits .container .nonprofit-analysis .chapter .btn:hover, .analyze-nonprofits .container .nonprofit-analysis .chapter .btn:focus {
      background-color: var(--sky-blue);
      border: 1px solid var(--sky-blue); }
    .analyze-nonprofits .container .nonprofit-analysis .chapter .btn .text {
      text-transform: uppercase;
      width: 98px;
      vertical-align: bottom;
      height: 17.7px;
      font-size: 13.3px;
      font-weight: 600;
      line-height: 1.33;
      letter-spacing: 0.5px;
      text-align: center;
      color: var(--white); }

.analyze-nonprofits .container .rating-process-row {
  padding: 22px 0 24px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.5); }
  .analyze-nonprofits .container .rating-process-row.first {
    border-top: 1px solid var(--white); }
  .analyze-nonprofits .container .rating-process-row .step-number {
    opacity: 0.49;
    font-size: 16.7px;
    font-weight: 400;
    line-height: 1.33; }
  .analyze-nonprofits .container .rating-process-row .step {
    display: flex;
    font-size: 26px;
    font-weight: 800;
    line-height: 1.33; }
    .analyze-nonprofits .container .rating-process-row .step .icon-step {
      margin-right: 14px;
      height: 25px !important;
      width: 25px !important; }
  .analyze-nonprofits .container .rating-process-row .step-detail {
    font-size: 20.8px;
    font-weight: 400;
    line-height: 1.33;
    margin-bottom: 0;
    color: white; }

.analyze-nonprofits .container .see-all-ratings {
  padding: 15px 25px;
  border-radius: 3px;
  transition: .3s;
  font-size: 16.8px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.21); }
  .analyze-nonprofits .container .see-all-ratings:hover, .analyze-nonprofits .container .see-all-ratings:focus {
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid rgba(255, 255, 255, 0.21); }

@media (max-width: 416px) {
  .analyze-nonprofits .container .rating-process-row.first {
    border-top: none; }
  .analyze-nonprofits .container .rating-process-row.last {
    border-bottom: none;
    padding-bottom: 0px; } }

.explore-impact-ratings h2 {
  margin-bottom: 20px; }

/* Input numbers */
.count {
  color: #000;
  display: inline-block;
  vertical-align: middle;
  width: 93px;
  margin-bottom: 4px; }

.top-list-cards-section .count {
  width: 85px; }

.qty-plus,
.qty-minus {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  color: #000;
  width: 30px;
  height: 30px;
  margin: 0 8px;
  text-align: center;
  border-radius: 50%;
  background-clip: padding-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  object-fit: contain; }
  .qty-plus:hover, .qty-plus:active,
  .qty-minus:hover,
  .qty-minus:active {
    background-color: rgba(228, 240, 244, 0.25) !important; }
  .qty-plus img,
  .qty-minus img {
    height: 30px;
    width: 30px; }

.qty {
  white-space: nowrap; }

/*Prevent text selection*/
input#calculator-amount {
  border: 0;
  background-color: transparent;
  font-size: 26px;
  font-weight: 600;
  outline: none;
  opacity: inherit;
  border-radius: inherit;
  line-height: inherit;
  letter-spacing: normal;
  padding: 0; }
  input#calculator-amount::-webkit-outer-spin-button, input#calculator-amount::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  input#calculator-amount:disabled {
    background-color: white; }

.input-group.prefix {
  display: inline;
  vertical-align: top;
  border-bottom: 2px solid var(--macaroni-and-cheese); }

/* MEDIA QUERIES */
@media (max-width: 991.99px) {
  .splash-section {
    background-color: #ebf3ff !important;
    height: 70vh; }
  #carouselLocalGiving {
    display: none; }
  #carouselLocalGivingTablet {
    display: none; }
  #carouselLocalGivingMobile {
    display: block; }
  .calculator-box {
    margin: -70px auto 0 auto; }
  .carousel-control-next {
    right: -18px;
    opacity: 1; }
  .carousel-control-prev {
    left: -18px;
    opacity: 1; }
  section.media-visibility h6 {
    line-height: 2;
    height: 25px; }
  section.media-visibility img {
    height: 30px;
    margin-top: 15px; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .calculator-box .calculate-impact-on {
    display: block; }
  #carouselLocalGiving {
    display: none; }
  #carouselLocalGivingTablet {
    display: block; }
  #carouselLocalGivingMobile {
    display: none; }
  .carousel-control-next {
    right: -65px;
    opacity: 1; }
  .carousel-control-prev {
    left: -65px;
    opacity: 1; }
  section.media-visibility h6 {
    line-height: 2;
    height: 25px; }
  section.media-visibility img {
    height: 25px; } }

@media (min-width: 1200px) {
  .calculator-box .message {
    display: flex;
    justify-content: space-between; }
  .calculator-box .calculator-box-left-items {
    margin-bottom: 0; }
  #carouselLocalGiving {
    display: block; }
  #carouselLocalGivingTablet {
    display: none; }
  #carouselLocalGivingMobile {
    display: none; }
  .carousel-control-next {
    right: -122px;
    opacity: 1; }
  .carousel-control-prev {
    left: -122px;
    opacity: 1; }
  section.media-visibility h6 {
    line-height: 2.2;
    height: 30px; }
  section.media-visibility img {
    height: 30px; } }

@media (max-width: 416px) {
  .calculator-box {
    margin: 20px auto 0 auto; } }

@media (max-width: 992px) {
  section.home-section .section-description, section.home-section .section-description-small {
    width: 100%; }
  .analyze-nonprofits .container .nonprofit-analysis .chapter {
    height: auto; }
  .analyze-nonprofits .container .nonprofit-analysis .chapter .btn {
    float: none;
    margin-top: 10px;
    margin-bottom: 10px; } }

@media (min-width: 992px) {
  section.home-section .container-fluid {
    padding: 100px; } }

@media (min-width: 1199.98px) and (max-width: 1280px) {
  .find-nonprofit {
    padding: 20px !important; } }

@media (max-width: 590px) {
  .find-nonprofit {
    font-size: 16.7px !important;
    font-weight: 600 !important;
    padding: 20px !important;
    letter-spacing: 0 !important; } }

@media (min-width: 768px) and (max-width: 992px) {
  section.home-section .container-fluid {
    padding: 50px; }
  section.media-visibility {
    /*height: 140px;*/
    background-color: var(--dark) !important;
    justify-items: center;
    font-size: 13.7px; }
    section.media-visibility .container-fluid {
      padding: 0px; }
    section.media-visibility h4 {
      font-size: 13.7px; }
    section.media-visibility img {
      height: 20px; } }

@media (max-width: 768px) {
  section.media-visibility {
    /*height: 212px;*/
    background-color: var(--dark) !important;
    justify-items: center;
    padding: 41px 5px 41px 5px; }
    section.media-visibility .container-fluid {
      padding: 0px; }
    section.media-visibility h4 {
      font-size: 13.7px; }
  section.home-section .container-fluid {
    padding: 50px 15px; }
  .container {
    max-width: 90% !important; } }

@media (max-width: 416px) {
  .container {
    max-width: 100% !important; }
  .qty-minus {
    margin-left: 0px; } }

/* Custom Splash CSS Style */
.page .header .container-fluid {
  padding: 40px 100px 40px 100px; }

.page .header .page-title {
  text-align: left;
  background-color: var(--white);
  width: 80%; }
  .page .header .page-title h1 {
    font-size: 40.7px;
    font-weight: 800;
    line-height: 1.33;
    margin-bottom: 0px; }
  .page .header .page-title p {
    font-size: 32.5px;
    font-weight: 400;
    line-height: 1.33; }
  .page .header .page-title p:last-child {
    margin-bottom: 0px; }
  .page .header .page-title p.normal {
    font-size: 20.8px;
    font-weight: 300; }

.page .toplist-page .container-fluid {
  padding: 48px 100px; }

.page .icon {
  display: block;
  height: 36px;
  margin-bottom: 28px;
  color: var(--clear-blue); }

.page .section-title {
  font-size: 40.7px;
  font-weight: 700;
  line-height: 1.33; }

.page .section-description {
  font-size: 40.7px;
  font-weight: 300;
  line-height: 1.33; }

.page .black {
  color: var(--black); }

.page .white {
  color: var(--white); }

.sub-page {
  background-color: transparent !important; }
  .sub-page .header {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
    .sub-page .header .overlay {
      background: rgba(40, 131, 255, 0.65); }
    .sub-page .header .container-fluid {
      padding: 20px 100px 65px 100px; }
    .sub-page .header .breadcrumb-page {
      margin-bottom: 35px; }
      .sub-page .header .breadcrumb-page a {
        font-size: 13.3px;
        font-weight: 300;
        letter-spacing: normal;
        line-height: 1.33;
        margin-left: 1px; }
      .sub-page .header .breadcrumb-page i {
        font-size: 13.3px; }
    .sub-page .header .page-title {
      width: 80%;
      text-align: left; }
      .sub-page .header .page-title .icon {
        display: block;
        height: 36px;
        margin-bottom: 10px; }
      .sub-page .header .page-title h1 {
        font-size: 40.7px;
        font-weight: 800;
        line-height: 1.33; }
      .sub-page .header .page-title p {
        font-size: 20.8px;
        font-weight: 400;
        line-height: 1.33;
        margin-top: 18px; }
  .sub-page .section-title {
    font-size: 40.7px;
    font-weight: 700;
    line-height: 1.33; }
  .sub-page .section-description {
    font-size: 40.7px;
    font-weight: 300;
    line-height: 1.33; }
  .sub-page .calculator-box {
    max-width: 100%;
    margin-bottom: 42px;
    height: 90px; }
  .sub-page .top-list-cards-section {
    background-color: #f5f4f3; }
    .sub-page .top-list-cards-section .container-fluid {
      padding: 0 100px 68px 100px; }

@media (max-width: 668px) {
  .sub-page .calculator-box {
    height: auto;
    min-height: 148px; } }

@media (max-width: 376px) {
  .sub-page .calculator-box {
    height: auto;
    min-height: 226px; } }

@media (max-width: 992px) {
  .page .header .container-fluid {
    padding: 40px 50px; }
  .page .header .page-title {
    width: 100%; }
  .page .toplist-page .container-fluid {
    padding: 40px 50px; } }

/* moving these out to be accessible without all the other styles coming along */
.passes-or-fails-governance-label {
  padding: 5px 8px 3px;
  border-radius: 3px;
  /* border: 1px solid #81a1aa; */
  text-transform: uppercase;
  font-size: 13.3px;
  color: var(--dark);
  font-weight: 400;
  letter-spacing: 0.5px;
  white-space: nowrap; }
  .passes-or-fails-governance-label object {
    background-color: var(--white);
    margin-right: 2px; }
    .passes-or-fails-governance-label object svg {
      fill: var(--dark); }

.passes-governance {
  background-color: var(--ice-blue); }

.fails-governance {
  background-color: var(--dark);
  color: var(--white); }

.nonprofit-label-icon {
  float: left;
  height: 20px !important;
  width: 20px !important; }

.nonprofit-impact-line {
  margin-bottom: 8px;
  margin-left: 32px;
  padding-top: 2px;
  line-height: 1.33; }

.nonprofit-governance-line {
  margin-left: 32px;
  padding-top: 2px;
  line-height: 1.33; }

.nonprofit-label {
  font-weight: 800;
  font-size: 16.7px; }

.nonprofit-governance-label {
  margin-right: 8px;
  display: inline-block;
  padding-bottom: 4px; }

.nonprofit-value {
  font-weight: 400;
  font-size: 16.7px;
  line-height: 1.6; }

.nonprofit_list .card-body {
  margin: 5px auto 5px auto;
  padding: 28px;
  border-radius: 3px;
  background-color: var(--white);
  border: solid 1px var(--duck-egg-blue);
  box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16); }
  .nonprofit_list .card-body .logo {
    width: 89px;
    height: 89px;
    border-radius: 3px;
    border: solid 1px var(--duck-egg-blue);
    background-color: var(--white); }
  .nonprofit_list .card-body .rating-stars {
    height: 25px; }
  .nonprofit_list .card-body .nonprofit-card {
    display: flex; }
    .nonprofit_list .card-body .nonprofit-card .rate-type {
      text-transform: uppercase;
      opacity: 0.7;
      font-size: 13.3px;
      font-weight: 400;
      line-height: 1.33;
      letter-spacing: 0.5px; }
    .nonprofit_list .card-body .nonprofit-card .nonprofit-title {
      text-decoration: none;
      font-size: 26px;
      font-weight: 800;
      line-height: 1.33; }
      .nonprofit_list .card-body .nonprofit-card .nonprofit-title:hover, .nonprofit_list .card-body .nonprofit-card .nonprofit-title:focus {
        text-decoration: none;
        color: var(--dark-grey-blue-two); }
    .nonprofit_list .card-body .nonprofit-card .nonprofit-summary {
      font-size: 13.3px;
      font-weight: 400;
      line-height: 1.33;
      margin: 17px auto 25px auto;
      text-align: left; }
      .nonprofit_list .card-body .nonprofit-card .nonprofit-summary .separator {
        margin-left: 10px;
        margin-right: 10px; }
  .nonprofit_list .card-body .calculator-container {
    position: relative;
    height: 100%;
    padding-left: 48px;
    border-left: solid 1px var(--duck-egg-blue);
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .nonprofit_list .card-body .calculator-container.request-rating {
      /*border-left: solid 1px transparent;*/
      flex-direction: column;
      justify-content: center; }
    .nonprofit_list .card-body .calculator-container .calculator-card {
      font-size: 26px;
      font-weight: 600;
      line-height: 1.33; }
    .nonprofit_list .card-body .calculator-container .footer a.learn-more {
      color: var(--black);
      text-decoration: underline;
      font-size: 16.7px;
      font-weight: 400;
      line-height: 1.33; }
      .nonprofit_list .card-body .calculator-container .footer a.learn-more:hover, .nonprofit_list .card-body .calculator-container .footer a.learn-more:focus {
        text-decoration: underline;
        font-size: 16.7px;
        color: var(--black); }
    .nonprofit_list .card-body .calculator-container .btn-orange {
      margin-right: 10px; }

.white {
  color: var(--white) !important; }

object.icon.white {
  filter: invert(100%) hue-rotate(0deg) saturate(0%) brightness(1000%) contrast(100%); }

@media (max-width: 668px) {
  .sub-page .header .page-title {
    width: 100%; }
  .nonprofit_list .card-body .nonprofit-card {
    display: block; }
  .nonprofit-order-block {
    margin-bottom: 14px; }
  .nonprofit-block {
    margin: 0; }
  .nonprofit_list .card-body .calculator-container {
    border-left: none;
    padding-left: 0; }
    .nonprofit_list .card-body .calculator-container .calculator-card {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid var(--duck-egg-blue);
      font-size: 16.7px !important; }
  .nonprofit_list .card-body.ve-card .footer {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid var(--duck-egg-blue); }
  .nonprofit_list .card-body .calculator-container .calculator-card {
    margin-bottom: 20px; }
  .sub-page .calculator-box {
    margin-bottom: 15px; } }

.top-list-blurb {
  margin-bottom: 96px; }

@media (max-width: 476px) {
  .top-list-blurb {
    margin-bottom: 0px; } }

/* Custom Splash CSS Style */
.page #pageLocalGiving .top-list-carousel .card-body {
  margin-bottom: 27px !important; }
  .page #pageLocalGiving .top-list-carousel .card-body img {
    /*max-height: 441px;*/ }

.local-giving-guide-cards-section {
  padding-bottom: 56px; }

@media (max-width: 668px) {
  .local-giving-guide-cards-section {
    padding-bottom: 0px; } }

#pageLocalGivingMobile {
  display: none; }

@media (max-width: 668px) {
  #pageLocalGiving {
    display: none; }
  #pageLocalGivingMobile {
    display: block; } }

.local-giving-guide-cards-section .select-container {
  margin-right: 20px; }
  .local-giving-guide-cards-section .select-container a {
    color: var(--black); }

.local-giving-block {
  margin-top: 48px; }
  .local-giving-block .page-title {
    /*padding-top: 20px;*/ }

@media (max-width: 376px) {
  .local-giving-block {
    margin-bottom: 20px; } }

.local-guides .container-fluid {
  padding: 79px 100px 83px 100px; }

.local-guides .calculator-box {
  margin-top: 45px;
  margin-bottom: 0; }

.local-guides .filter-by {
  height: 22px;
  opacity: 0.58;
  font-size: 13.3px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.5px;
  margin-top: 37px;
  margin-bottom: 11px;
  text-transform: uppercase; }

.local-guides .local-giving-block .header .icon {
  margin-bottom: 11px; }

.local-guides .cause-anchor {
  position: relative;
  top: -30px; }

@media (max-width: 668px) {
  .local-guides .calculator-box {
    margin-top: 20px;
    margin-bottom: 0px; }
  .local-guide:last-child .nonprofit_list .row:last-child .card {
    margin-bottom: 0px; } }

@media (max-width: 416px) {
  .local-guides .calculator-box {
    margin-top: 20px;
    margin-bottom: 0px; }
  .local-guide:last-child .nonprofit_list .row:last-child .card {
    margin-bottom: 0px; } }

@media (max-width: 376px) {
  .sub-page .local-giving-guide-cards-section .calculator-box {
    min-height: 197px; } }

/* Custom Splash CSS Style */
.nonprofit-page .container-fluid {
  padding: 79px 5% 29px 5% !important; }

.nonprofit-page .header .page-title {
  min-height: 100px; }
  .nonprofit-page .header .page-title .rating-label {
    border-radius: 3px;
    vertical-align: center;
    padding: 5px 16px;
    font-size: 13.3px;
    font-weight: 700;
    text-transform: uppercase;
    border-color: var(--very-light-blue);
    letter-spacing: 0.5px;
    background-color: var(--very-light-blue);
    margin-bottom: 10px;
    display: inline-block;
    line-height: normal; }
  .nonprofit-page .header .page-title h1 {
    font-size: 40.7px;
    font-weight: 800;
    line-height: 1.33;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    margin-bottom: 3px;
    margin-top: 24px; }

.nonprofit-page .header .nonprofit-detail {
  padding-bottom: 0;
  margin-bottom: 0; }
  .nonprofit-page .header .nonprofit-detail p {
    margin-bottom: 0; }
  .nonprofit-page .header .nonprofit-detail .nonprofit-box-stars {
    margin-bottom: 29px; }
    .nonprofit-page .header .nonprofit-detail .nonprofit-box-stars img {
      height: 23px; }
    .nonprofit-page .header .nonprofit-detail .nonprofit-box-stars .top-nonprofit {
      font-size: 16.7px;
      font-weight: 400;
      line-height: 1.33;
      position: relative;
      top: 4px;
      margin-right: 14px;
      white-space: nowrap; }
      .nonprofit-page .header .nonprofit-detail .nonprofit-box-stars .top-nonprofit .top-nonprofit-icon {
        height: 16px;
        width: 16px;
        display: inline-block;
        position: relative;
        top: 2px;
        margin-right: 6px; }
        .nonprofit-page .header .nonprofit-detail .nonprofit-box-stars .top-nonprofit .top-nonprofit-icon svg {
          fill: #ffc12c; }
  .nonprofit-page .header .nonprofit-detail .logo-block {
    margin-right: 28px;
    width: 90px; }
    .nonprofit-page .header .nonprofit-detail .logo-block img.logo {
      width: 89px;
      height: 89px;
      border-radius: 3px;
      border: solid 1px var(--duck-egg-blue);
      background-color: var(--white); }
  .nonprofit-page .header .nonprofit-detail .impact,
  .nonprofit-page .header .nonprofit-detail .governance {
    padding-right: 10%;
    font-size: 20.8px;
    line-height: 1.33; }
    .nonprofit-page .header .nonprofit-detail .impact .bold,
    .nonprofit-page .header .nonprofit-detail .governance .bold {
      font-weight: 800; }
    .nonprofit-page .header .nonprofit-detail .impact .nonprofit-value,
    .nonprofit-page .header .nonprofit-detail .governance .nonprofit-value {
      margin-left: 2px;
      position: relative;
      top: -1px; }
  .nonprofit-page .header .nonprofit-detail .impact {
    margin-bottom: 15px; }
  .nonprofit-page .header .nonprofit-detail .governance {
    margin-bottom: 29px; }
  .nonprofit-page .header .nonprofit-detail .social-buttons {
    margin-top: 28px; }
  .nonprofit-page .header .nonprofit-detail .donate-btn.request-rating {
    background-color: var(--very-light-blue);
    border-color: var(--very-light-blue) !important; }
    .nonprofit-page .header .nonprofit-detail .donate-btn.request-rating:hover, .nonprofit-page .header .nonprofit-detail .donate-btn.request-rating:focus {
      border-color: var(--dark);
      background-color: var(--very-light-blue);
      color: var(--dark);
      text-decoration: none; }
  .nonprofit-page .header .nonprofit-detail .nonprofit-programs {
    border-left: 1px solid var(--duck-egg-blue);
    padding-left: 45px; }
    .nonprofit-page .header .nonprofit-detail .nonprofit-programs .mini-block {
      margin-bottom: 24px; }
  .nonprofit-page .header .nonprofit-detail .nonprofit-mission {
    padding-right: 30px; }
    .nonprofit-page .header .nonprofit-detail .nonprofit-mission .mini-block {
      margin-bottom: 29px; }
  .nonprofit-page .header .nonprofit-detail .nonprofit-programs .mini-block .icon, .nonprofit-page .header .nonprofit-detail .nonprofit-mission .mini-block .icon {
    float: left;
    height: 20px;
    width: 20px;
    margin-bottom: 0; }
  .nonprofit-page .header .nonprofit-detail .nonprofit-programs .mini-block p, .nonprofit-page .header .nonprofit-detail .nonprofit-mission .mini-block p {
    margin-left: 34px;
    font-weight: 800; }
    .nonprofit-page .header .nonprofit-detail .nonprofit-programs .mini-block p.light, .nonprofit-page .header .nonprofit-detail .nonprofit-mission .mini-block p.light {
      font-weight: 400; }
      .nonprofit-page .header .nonprofit-detail .nonprofit-programs .mini-block p.light a, .nonprofit-page .header .nonprofit-detail .nonprofit-mission .mini-block p.light a {
        text-decoration: underline;
        color: var(--orange); }

.nonprofit-page .home-section.calculator .container {
  padding-top: 47px !important;
  padding-bottom: 47px !important; }
  .nonprofit-page .home-section.calculator .container .message {
    font-size: 26px;
    font-weight: 600;
    line-height: 1.33;
    color: var(--white); }
    .nonprofit-page .home-section.calculator .container .message .icon {
      width: 30px;
      height: 30px; }
    .nonprofit-page .home-section.calculator .container .message input, .nonprofit-page .home-section.calculator .container .message select, .nonprofit-page .home-section.calculator .container .message textarea {
      color: var(--white); }
    .nonprofit-page .home-section.calculator .container .message .input-group.prefix {
      border-bottom: 2px solid var(--white); }
  .nonprofit-page .home-section.calculator .container .calculator-card {
    font-size: 40.7px;
    font-weight: 600;
    line-height: 1.23;
    color: var(--white); }

.nonprofit-page .home-section.calculator .icon:hover, .nonprofit-page .home-section.calculator .icon:active {
  filter: invert(100%) hue-rotate(0deg) saturate(0%) brightness(1000%) contrast(100%);
  background-color: transparent !important; }

.nonprofit-page .home-section.rated-program .container-fluid {
  padding: 0 !important; }
  .nonprofit-page .home-section.rated-program .container-fluid img.rating-stars {
    height: 17px !important;
    margin-bottom: 13px;
    margin-right: 14px; }

.nonprofit-page .home-section.rated-program .container-fluid-with-sidebar-right-background {
  position: relative; }

.nonprofit-page .home-section.rated-program .rated-sidebar-right-background {
  position: absolute;
  width: calc(100% - 1140px);
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #f5f4f3; }

.nonprofit-page .home-section.rated-program .rated-sidebar {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f5f4f3;
  padding-left: 61px; }
  .nonprofit-page .home-section.rated-program .rated-sidebar .title {
    font-size: 20.8px;
    font-weight: 700;
    margin-bottom: 25px;
    line-height: 1.33; }
    .nonprofit-page .home-section.rated-program .rated-sidebar .title.news {
      margin-top: 79px; }
  .nonprofit-page .home-section.rated-program .rated-sidebar .card {
    margin: 11px auto 11px auto;
    max-width: 415px; }
    .nonprofit-page .home-section.rated-program .rated-sidebar .card .title {
      text-decoration: none;
      text-align: left;
      font-size: 20.8px;
      font-weight: 700;
      margin-bottom: 0px;
      line-height: 1.33; }
      .nonprofit-page .home-section.rated-program .rated-sidebar .card .title a:hover, .nonprofit-page .home-section.rated-program .rated-sidebar .card .title a:focus {
        text-decoration: none; }
    .nonprofit-page .home-section.rated-program .rated-sidebar .card .card-title {
      font-size: 16.7px !important;
      font-weight: 800;
      line-height: 1.33; }
    .nonprofit-page .home-section.rated-program .rated-sidebar .card p.summary {
      opacity: 0.7;
      font-size: 13.3px;
      font-weight: 400;
      line-height: 1.33; }
    .nonprofit-page .home-section.rated-program .rated-sidebar .card .impact-statement {
      font-size: 16.7px;
      font-weight: 400;
      line-height: 1.33; }
      .nonprofit-page .home-section.rated-program .rated-sidebar .card .impact-statement strong {
        font-weight: 800; }
      .nonprofit-page .home-section.rated-program .rated-sidebar .card .impact-statement .small {
        font-size: 10px;
        margin-top: 10px; }

.nonprofit-page .home-section.rated-program .rated-content {
  padding-top: 50px;
  padding-right: 0;
  padding-left: 0; }
  .nonprofit-page .home-section.rated-program .rated-content .section-description {
    width: 100%; }
  .nonprofit-page .home-section.rated-program .rated-content .nav-tabs {
    height: auto;
    align-items: center; }
    .nonprofit-page .home-section.rated-program .rated-content .nav-tabs.border-bottom {
      border-bottom: 1px solid var(--dark) !important;
      z-index: 10; }
    .nonprofit-page .home-section.rated-program .rated-content .nav-tabs li {
      height: auto; }
      .nonprofit-page .home-section.rated-program .rated-content .nav-tabs li a {
        margin-right: 18px;
        height: 51px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        font-size: 16.7px;
        font-weight: 600;
        line-height: 1.33;
        color: var(--black);
        padding: 15px 22px;
        display: block; }
        .nonprofit-page .home-section.rated-program .rated-content .nav-tabs li a:hover {
          background-color: var(--ice-blue);
          color: var(--black);
          text-decoration: none; }
      .nonprofit-page .home-section.rated-program .rated-content .nav-tabs li .active {
        background-color: var(--dark);
        color: var(--white); }
        .nonprofit-page .home-section.rated-program .rated-content .nav-tabs li .active:hover {
          background-color: var(--dark);
          color: var(--white);
          text-decoration: none; }
  .nonprofit-page .home-section.rated-program .rated-content .tab-content {
    color: var(--black); }
    .nonprofit-page .home-section.rated-program .rated-content .tab-content #governance-report,
    .nonprofit-page .home-section.rated-program .rated-content .tab-content #impact-report,
    .nonprofit-page .home-section.rated-program .rated-content .tab-content #methodology {
      padding-right: 5%; }
    .nonprofit-page .home-section.rated-program .rated-content .tab-content .download-analysis {
      font-size: 10px;
      font-weight: 400;
      margin: 10px auto 30px;
      display: block;
      padding: 8px 15px;
      color: var(--white);
      max-width: 235px;
      border-radius: 3px;
      background-color: var(--clear-blue); }
    .nonprofit-page .home-section.rated-program .rated-content .tab-content .tab-title {
      margin-top: 31px;
      margin-bottom: 16px;
      font-size: 26px;
      font-weight: 700;
      line-height: 1.33;
      color: var(--black); }
      .nonprofit-page .home-section.rated-program .rated-content .tab-content .tab-title i {
        font-size: 20px;
        font-weight: 400;
        opacity: .7; }
    .nonprofit-page .home-section.rated-program .rated-content .tab-content .section-title {
      margin-top: 15px;
      margin-bottom: 0;
      font-weight: 800;
      font-size: 16.7px;
      line-height: 1.33;
      color: var(--black); }
    .nonprofit-page .home-section.rated-program .rated-content .tab-content .section-description {
      font-size: 16.7px;
      font-weight: 400;
      line-height: 1.33; }
    .nonprofit-page .home-section.rated-program .rated-content .tab-content .section-title-tip {
      margin-top: 31px; }
    .nonprofit-page .home-section.rated-program .rated-content .tab-content li.section-description {
      margin-bottom: 15px; }
    .nonprofit-page .home-section.rated-program .rated-content .tab-content #methodology {
      margin-top: 0;
      margin-right: 0; }
      .nonprofit-page .home-section.rated-program .rated-content .tab-content #methodology .step {
        font-size: 20.8px;
        font-weight: 700; }
        .nonprofit-page .home-section.rated-program .rated-content .tab-content #methodology .step .icon-step {
          margin-bottom: 10px;
          filter: invert(0%) hue-rotate(0deg) saturate(0%) brightness(1000%) contrast(100%); }
      .nonprofit-page .home-section.rated-program .rated-content .tab-content #methodology .section-description.first {
        margin-bottom: 30px; }
      .nonprofit-page .home-section.rated-program .rated-content .tab-content #methodology .section-description.last {
        margin-top: 30px; }
      .nonprofit-page .home-section.rated-program .rated-content .tab-content #methodology .rating-process-row {
        padding: 0px; }

@media (max-width: 992px) {
  .nonprofit-page .home-section.rated-program .rated-sidebar {
    background-color: #fff;
    padding-left: 0px; }
    .nonprofit-page .home-section.rated-program .rated-sidebar .card.sidebar-rating-card {
      margin: 11px 0; } }

.governance-check-page .header .page-title h1 {
  margin-bottom: 29px; }

.governance-check-page section.home-section .overlay.blue {
  background-color: var(--clear-blue); }

.governance-check-page .action-buttons {
  display: flex;
  align-items: center; }
  .governance-check-page .action-buttons .action-buttons-or {
    margin: 0 10px; }

@media (max-width: 416px) {
  .governance-check-page .action-buttons {
    display: block; }
  .governance-check-page .action-buttons-or {
    display: none; }
  .governance-check-page #RatingRequest {
    margin-top: 15px; }
  .governance-check-page p.small {
    display: none; } }

.donate-sticky-footer {
  padding: 24px 7%;
  box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.1);
  border: solid 1px var(--duck-egg-blue);
  background-color: var(--white); }
  .donate-sticky-footer .donate-btn {
    width: 150px; }
  .donate-sticky-footer .nonprofit-card {
    margin-top: 0;
    margin-bottom: 0px;
    display: flex;
    align-items: center; }
  .donate-sticky-footer .title {
    font-size: 19px;
    font-weight: 800;
    line-height: 1.33; }
  .donate-sticky-footer .rating-stars {
    height: 19px;
    margin-bottom: 0; }
  .donate-sticky-footer .nonprofit-sticky-donate {
    display: flex;
    align-items: center; }
  .donate-sticky-footer .impact-statement-sticky-donate {
    font-size: 13.3px;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .donate-sticky-footer .impact-statement-sticky-donate p {
      margin: 0 !important; }
  .donate-sticky-footer .sticky-donate-icon {
    margin-left: 30px;
    cursor: pointer;
    height: 20px;
    width: 20px; }

.ui-tooltip {
  width: 394px;
  height: 161px;
  box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16);
  padding: 19px;
  margin-left: -180px !important;
  background-color: var(--white); }

.ui-tooltip-content::after,
.ui-tooltip-content::before {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute; }

.ui-tooltip-content::after {
  transform: rotate(180deg);
  border-color: rgba(118, 118, 118, 0);
  border-top-color: var(--white);
  border-width: 10px;
  left: 50%;
  margin-left: -10px; }

.ui-tooltip-content::before {
  transform: rotate(180deg);
  border-color: rgba(118, 118, 118, 0);
  border-top-color: var(--white);
  border-width: 11px;
  left: 50%;
  margin-left: -11px; }

@media (max-width: 991.98px) {
  .donate-sticky-footer {
    padding: 24px 1%; }
  .nonprofit-page .header .nonprofit-detail .nonprofit-programs {
    border-left: 1px solid transparent;
    padding-left: 0;
    padding-top: 90px; }
  .nonprofit-page .home-section.rated-program .rated-content {
    padding-left: 0;
    padding-right: 0; }
  .nonprofit-page .home-section.rated-program .container-fluid {
    padding-bottom: 0 !important; } }

@media (min-width: 991.98px) and (max-width: 1199.98px) {
  .nonprofit-page .home-section.rated-program .rated-content .nav-tabs li a {
    margin-right: 10px; } }

.nonprofit-programs-collapse {
  display: none; }

@media (max-width: 416px) {
  .container {
    padding-left: 20px;
    padding-right: 20px; }
  .nonprofit-programs {
    display: none; }
  .nonprofit-programs-collapse.nonprofit-programs {
    display: block;
    padding-top: 0px !important; }
  .nonprofit-page .home-section.rated-program .rated-content {
    padding: 30px 15px 0; }
  .report-callout {
    display: none; }
  .nonprofit-page .home-section.rated-program .rated-content .tab-content #governance-report, .nonprofit-page .home-section.rated-program .rated-content .tab-content #impact-report, .nonprofit-page .home-section.rated-program .rated-content .tab-content #methodology {
    padding-right: 0px; }
  .nonprofit-page .home-section.rated-program .rated-content .nav-tabs li {
    margin-bottom: 5px; }
  .nonprofit-page .home-section.rated-program .rated-content .nav-tabs li a {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; } }

.rating-stars {
  height: 23px;
  margin-right: 14px; }

p.governance-test {
  margin-bottom: 8px; }

.nonprofit-page .home-section.rated-program .rated-content .tab-content .section-description.governance-source {
  font-size: 13.3px;
  margin-top: 14px; }

@media (max-width: 668px) {
  .sticky-footer {
    padding: 0;
    visibility: hidden;
    border: 0;
    width: 0;
    height: 0; } }

.nonprofit-page .calculator-card-label {
  display: none; }

@media (max-width: 668px) {
  .nonprofit-page .calculator-card {
    margin-top: 70px; } }

@media (max-width: 416px) {
  .nonprofit-page .calculator-card {
    margin-top: 20px; }
  .action-buttons .btn {
    min-width: 230px; } }

/* Custom Splash CSS Style */
.search-header {
  padding: 70px 5% 0 5%; }
  .search-header .form-inline {
    min-height: 60px;
    margin-top: 40px;
    margin-bottom: 42px; }
    .search-header .form-inline .input-group {
      width: 100%;
      align-items: flex-end; }
      .search-header .form-inline .input-group .form-control {
        border-top: transparent;
        border-left: transparent;
        border-right: transparent;
        border-bottom: solid 1px var(--dark);
        border-radius: 0;
        font-size: 20.8px;
        height: auto;
        padding: 16px 20px; }
        .search-header .form-inline .input-group .form-control:focus {
          color: #495057;
          background-color: #fff;
          border-top: transparent;
          border-left: transparent;
          border-right: transparent;
          border-bottom: solid 1px var(--dark);
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0); }
      .search-header .form-inline .input-group .input-group-addon {
        border-bottom: solid 1px var(--dark);
        padding-bottom: 20px; }
  .search-header .filter-by {
    margin-bottom: 56px;
    font-size: 13.3px;
    font-weight: bold; }

/* PULLED OUT OF SEARCH AND CALCULATOR BOX TO BE USED ELSEWHERE */
.nonprofit-summary {
  font-size: 13.3px;
  font-weight: 400;
  line-height: 1.33;
  margin: 17px auto 25px auto;
  text-align: left; }
  .nonprofit-summary .separator {
    margin-left: 10px;
    margin-right: 10px; }

.impact-audited-nonprofit {
  font-size: 16.7px;
  font-weight: 400;
  line-height: 1.33;
  position: relative;
  top: 5px;
  margin-left: 0px;
  display: inline-block; }
  .impact-audited-nonprofit .impact-audited-nonprofit-icon {
    height: 16px;
    display: inline-block;
    position: relative;
    top: 2px;
    margin-right: 6px; }
    .impact-audited-nonprofit .impact-audited-nonprofit-icon svg {
      fill: #ffc12c; }

.search-and-calculator-box {
  background-color: #f5f4f3 !important;
  padding: 0 5% 18px; }
  .search-and-calculator-box .calculator-box {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 33px;
    height: auto !important; }
    .search-and-calculator-box .calculator-box .search-filter-container {
      margin-right: 23px; }
  .search-and-calculator-box .nonprofit_list .card.card-body {
    margin: 5px auto 5px auto;
    padding: 28px 46px 28px 28px;
    border-radius: 3px;
    background-color: var(--white);
    border: solid 1px var(--duck-egg-blue);
    box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16); }
    .search-and-calculator-box .nonprofit_list .card.card-body .logo {
      width: 89px;
      height: 89px;
      margin-left: 1px;
      border-radius: 3px;
      border: solid 1px var(--duck-egg-blue);
      background-color: var(--white); }
    .search-and-calculator-box .nonprofit_list .card.card-body .rating-stars {
      height: 25px; }
    .search-and-calculator-box .nonprofit_list .card.card-body .nonprofit-card {
      display: flex; }
      .search-and-calculator-box .nonprofit_list .card.card-body .nonprofit-card .rate-type {
        text-transform: uppercase;
        opacity: 0.7;
        font-size: 13.3px;
        font-weight: 400;
        line-height: 1.33;
        letter-spacing: 0.5px; }
      .search-and-calculator-box .nonprofit_list .card.card-body .nonprofit-card .nonprofit-title {
        text-decoration: none;
        font-size: 26px;
        font-weight: 800;
        line-height: 1.33; }
        .search-and-calculator-box .nonprofit_list .card.card-body .nonprofit-card .nonprofit-title:hover, .search-and-calculator-box .nonprofit_list .card.card-body .nonprofit-card .nonprofit-title:focus {
          text-decoration: none; }
    .search-and-calculator-box .nonprofit_list .card.card-body .calculator-container {
      position: relative;
      height: 100%;
      padding-left: 48px;
      border-left: solid 1px var(--duck-egg-blue);
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .search-and-calculator-box .nonprofit_list .card.card-body .calculator-container.request-rating {
        /*border-left: solid 1px transparent;*/ }
      .search-and-calculator-box .nonprofit_list .card.card-body .calculator-container .calculator-card {
        font-size: 26px;
        font-weight: 600;
        line-height: 1.33; }
      .search-and-calculator-box .nonprofit_list .card.card-body .calculator-container .footer a.learn-more, .search-and-calculator-box .nonprofit_list .card.card-body .calculator-container .footer a.learn-how {
        color: var(--black);
        text-decoration: underline;
        font-size: 16.7px;
        font-weight: 400;
        line-height: 1.33; }
        .search-and-calculator-box .nonprofit_list .card.card-body .calculator-container .footer a.learn-more:hover, .search-and-calculator-box .nonprofit_list .card.card-body .calculator-container .footer a.learn-more:focus, .search-and-calculator-box .nonprofit_list .card.card-body .calculator-container .footer a.learn-how:hover, .search-and-calculator-box .nonprofit_list .card.card-body .calculator-container .footer a.learn-how:focus {
          text-decoration: underline;
          font-size: 16.7px;
          color: var(--black); }
    .search-and-calculator-box .nonprofit_list .card.card-body.ve-card .calculator-card {
      height: 0px;
      margin-bottom: 0px;
      padding-top: 0px !important; }
  .search-and-calculator-box .page-link {
    position: relative;
    display: block;
    padding: 10px 15px;
    line-height: 1.25;
    color: var(--black);
    margin: 25px 5px;
    border-radius: 3px;
    background-color: var(--macaroni-and-cheese);
    border: 1px solid var(--macaroni-and-cheese); }
    .search-and-calculator-box .page-link:hover, .search-and-calculator-box .page-link:focus {
      border: 1px solid var(--dark-grey-blue-two);
      color: var(--dark-grey-blue-two); }
  .search-and-calculator-box .page-item.active .page-link {
    background-color: var(--dark-grey-blue-two);
    border: 1px solid var(--dark-grey-blue-two); }
    .search-and-calculator-box .page-item.active .page-link:hover, .search-and-calculator-box .page-item.active .page-link:focus {
      background-color: var(--dark-grey-blue-two);
      border: 1px solid var(--dark-grey-blue-two);
      color: var(--white); }
  .search-and-calculator-box .dropdown-toggle .fa-caret-down {
    padding-left: 8px; }
  .search-and-calculator-box .dropdown-menu {
    margin-top: 2px;
    padding: 0;
    border: 0;
    background: none;
    min-width: 200px; }
    .search-and-calculator-box .dropdown-menu:before {
      top: 25px; }
    .search-and-calculator-box .dropdown-menu:after {
      top: 26px; }
    .search-and-calculator-box .dropdown-menu .dropdown-menu-container {
      margin-top: 35px;
      padding: 25px 18px 16px 18px;
      background-color: #fff;
      background-clip: padding-box;
      box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16);
      border: solid 1px var(--duck-egg-blue);
      border-radius: 3px; }
    .search-and-calculator-box .dropdown-menu .stars {
      position: relative;
      top: -2px; }
    .search-and-calculator-box .dropdown-menu .dropdown-item {
      padding: 6px 10px 6px 10px !important;
      height: inherit;
      line-height: 1.33;
      display: flex;
      align-items: center;
      cursor: pointer; }
      .search-and-calculator-box .dropdown-menu .dropdown-item label {
        display: flex;
        align-items: center; }
      .search-and-calculator-box .dropdown-menu .dropdown-item span {
        cursor: pointer;
        line-height: 1; }
    .search-and-calculator-box .dropdown-menu .title {
      font-size: 16.7px;
      font-weight: 800;
      line-height: 1.33;
      margin-left: 10px;
      margin-bottom: 10px; }
    .search-and-calculator-box .dropdown-menu .mini-block {
      display: table-cell; }
      .search-and-calculator-box .dropdown-menu .mini-block .item {
        width: 357px;
        margin: 0px; }
      .search-and-calculator-box .dropdown-menu .mini-block:last-child .item {
        width: 357px; }
      .search-and-calculator-box .dropdown-menu .mini-block:last-child {
        padding-right: 1px; }
    .search-and-calculator-box .dropdown-menu input[type=checkbox], .search-and-calculator-box .dropdown-menu input[type=radio] {
      box-sizing: border-box;
      padding: 0;
      width: 18px;
      height: 18px;
      border-radius: 3px;
      border: solid 1px var(--dark);
      background-color: var(--white); }
    .search-and-calculator-box .dropdown-menu.show {
      border-radius: 3px;
      background-color: var(--white);
      border: solid 1px var(--duck-egg-blue);
      box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16); }

ul.bs-autocomplete-menu {
  box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16);
  border: solid 1px var(--duck-egg-blue);
  background-color: var(--white);
  padding: 25px 17px; }
  ul.bs-autocomplete-menu:after, ul.bs-autocomplete-menu:before {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    transform: rotate(180deg);
    border-top-color: var(--white);
    border-width: 10px;
    left: 50%;
    margin-left: -10px; }
  ul.bs-autocomplete-menu a:hover, ul.bs-autocomplete-menu a:focus {
    border-radius: 3px;
    background-color: var(--ice-blue); }

.call-to-action-brand-btn {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16.7px;
  max-width: 250px;
  line-height: 1.33;
  letter-spacing: 0.5px;
  height: 60px;
  border-radius: 3px;
  background-color: var(--macaroni-and-cheese);
  margin: auto;
  padding: 22px 5%;
  color: var(--dark);
  text-decoration: none;
  border: 1px solid var(--macaroni-and-cheese); }
  .call-to-action-brand-btn:hover, .call-to-action-brand-btn:focus {
    border-color: var(--dark);
    background-color: var(--macaroni-and-cheese);
    color: var(--dark);
    text-decoration: none; }

@media (max-width: 668px) {
  .ve-card .btn.btn-orange {
    display: inline-block !important;
    margin-bottom: 10px !important; }
  .ve-card .btn-or {
    position: relative;
    top: -5px; }
  .search-and-calculator-box .nonprofit_list .card.card-body {
    padding: 28px; }
  .search-and-calculator-box .nonprofit_list .card.card-body .calculator-container {
    border-left: none;
    padding-left: 0; }
    .search-and-calculator-box .nonprofit_list .card.card-body .calculator-container .calculator-card {
      padding: 20px 0 0; }
  .search-header {
    padding: 30px 0 120px; }
    .search-header.page .section-title {
      font-size: 32px; }
    .search-header .search-nonprofit-input {
      margin-right: 0px !important; }
    .search-header .filter-by {
      font-size: 13.3px;
      font-weight: bold;
      position: absolute;
      margin-top: 47px;
      left: 30px; }
    .search-header .form-inline {
      margin-bottom: 0px; }
    .search-header .btn {
      width: 100%;
      margin-top: 20px; }
  .search-and-calculator-box .calculator-box-left-items {
    /* Disabling filters for mobile temporarily */
    display: none; }
  .search-and-calculator-box .calculator-box .search-filter-container {
    margin-right: 50px;
    margin-bottom: 10px; }
  .search-and-calculator-box {
    padding: 0px 0 7px 0; } }

@media (max-width: 416px) {
  .search-header {
    padding: 25px 0; } }

.calculator-box-right-items .count {
  color: var(--macaroni-and-cheese); }

.calculator-box-right-items .input-group-addon {
  color: var(--macaroni-and-cheese); }

.calculator-number {
  color: var(--macaroni-and-cheese); }

.calculator-number-placeholder-wrapper {
  min-width: 17px;
  display: inline-block;
  color: var(--macaroni-and-cheese) !important; }

.nonprofit-page .calculator-number-placeholder-wrapper {
  min-width: 30px; }

.top-list-carousel .calculator-number-placeholder-wrapper {
  min-width: 20px;
  display: inline-block;
  color: var(--macaroni-and-cheese) !important; }

.nonprofit-page .calculator-number-raw.calculator-number-active,
.nonprofit-page .calculator-number.calculator-number-active,
.nonprofit-page .calculator-number-placeholder-wrapper,
.nonprofit-page .calculator-number-placeholder,
.nonprofit-page .calculator-number {
  color: white !important; }

.calculator-number-raw {
  display: none; }

.calculator-number-raw.calculator-number-active, .calculator-number.calculator-number-active {
  color: var(--macaroni-and-cheese); }

.calculator-card-label {
  color: var(--slate);
  font-size: 13.3px;
  text-transform: uppercase;
  margin-bottom: 4px;
  font-weight: 800; }

@media (max-width: 668px) {
  .calculator-card-label {
    display: none; }
  .search-header .filter-by {
    visibility: hidden; } }

.input-group-addon.prefix {
  width: 9px;
  display: inline-block; }

@media (max-width: 668px) {
  .search-and-calculator-box .calculator-box {
    height: 89px !important; } }

@media (max-width: 416px) {
  .search-and-calculator-box .calculator-box {
    height: 137px !important;
    min-height: 137px !important;
    max-height: 137px !important;
    margin-bottom: 15px !important; } }

.page.top-center .top-lists-cards .card .title {
  height: auto !important; }

.page.faq .page-title {
  min-height: auto; }
  .page.faq .page-title p {
    font-size: 20.8px;
    font-weight: 400;
    line-height: 1.33; }

.page.faq .accordion-toggle[aria-expanded="false"] img.open {
  display: none; }

.page.faq .accordion-toggle[aria-expanded="false"] img.closed {
  display: block; }

.page.faq .accordion-toggle[aria-expanded="true"] img.open {
  display: block; }

.page.faq .accordion-toggle[aria-expanded="true"] img.closed {
  display: none; }

.page.faq .accordion-toggle {
  font-size: 32.5px;
  font-weight: 600;
  line-height: 1.34;
  cursor: pointer; }
  .page.faq .accordion-toggle img.icon {
    float: right;
    height: 36px;
    width: 36px; }
  .page.faq .accordion-toggle span {
    width: 90%;
    display: inline-block; }

@media (max-width: 668px) {
  .page.faq .accordion-toggle span {
    width: 85%; } }

.home-section.faq p {
  font-size: 16.7px;
  font-weight: 500;
  line-height: 1.33; }

.home-section.faq .subtitle {
  font-family: "Nunito Sans" !important;
  font-size: 20.8px !important;
  font-weight: 700 !important;
  line-height: 1.33 !important;
  margin-top: 30px !important;
  color: #000000 !important; }

.base-page {
  font-family: "Nunito Sans" !important;
  font-weight: 400 !important;
  line-height: 1.33 !important;
  color: #000000 !important; }
  .base-page .container-fluid {
    padding: 50px 30px !important; }
  .base-page .container {
    padding: 56px 30px; }
    .base-page .container.container-skinny {
      max-width: 882px; }
      .base-page .container.container-skinny .page-title {
        width: 100%; }
  .base-page .page-title {
    padding-bottom: 36px;
    width: 80%; }
    .base-page .page-title img, .base-page .page-title .icon {
      height: 36px;
      margin-bottom: 10px; }
    .base-page .page-title .badge {
      font-size: 13px !important;
      font-weight: 500 !important;
      line-height: 1.33 !important;
      padding: 5px 10px !important;
      border-radius: 5px !important; }
    .base-page .page-title h1 {
      font-size: 40.7px;
      font-weight: 800;
      line-height: 1.33; }
    .base-page .page-title p {
      font-size: 20.8px !important;
      font-weight: 300 !important;
      line-height: 1.33; }
  .base-page .rating-stars {
    margin: 10px auto 15px auto; }
  .base-page p {
    font-size: 16.7px !important;
    font-weight: 400 !important;
    line-height: 1.33 !important; }
  .base-page h1 {
    font-family: "Nunito Sans" !important;
    font-size: 40.7px !important;
    font-weight: 800 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.34 !important;
    letter-spacing: normal !important;
    color: var(--dark) !important; }
  .base-page h2, .base-page h3, .base-page h4 {
    margin-top: 1.5rem; }
  .base-page h2 {
    font-family: "Nunito Sans" !important;
    font-size: 32.5px !important;
    font-weight: 800 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.34 !important;
    letter-spacing: normal !important;
    color: var(--dark) !important; }
  .base-page h3 {
    font-family: "Nunito Sans" !important;
    font-size: 26px !important;
    font-weight: 700 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.33 !important;
    letter-spacing: normal !important;
    color: var(--dark) !important; }
  .base-page h4 {
    font-family: "Nunito Sans" !important;
    font-size: 20.8px;
    font-weight: 600 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.33 !important;
    letter-spacing: normal !important;
    color: #000 !important; }
    .base-page h4.title {
      font-size: 26px !important;
      font-weight: 700 !important; }
  .base-page h5 {
    font-family: "Nunito Sans" !important;
    font-size: 16.7px;
    line-height: 1.33;
    letter-spacing: normal;
    font-weight: 700 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    color: #000 !important; }
  .base-page h6 {
    font-family: "Nunito Sans" !important;
    font-size: 16.7px;
    font-weight: 400;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.33 !important;
    letter-spacing: normal !important;
    color: #000 !important; }
    .base-page h6.card-title {
      margin-top: 15px;
      margin-bottom: 10px;
      font-size: 20.8px;
      font-weight: 600;
      font-stretch: normal !important; }
  .base-page .card-header {
    background-color: var(--ice-blue);
    font-family: "Nunito Sans" !important;
    font-size: 16.7px;
    font-weight: 600;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.33 !important;
    letter-spacing: normal !important;
    color: var(--dark) !important; }
  .base-page .top-lists-cards {
    margin-bottom: 50px; }
    .base-page .top-lists-cards a {
      text-decoration: none !important; }
    .base-page .top-lists-cards .card-body {
      text-align: center;
      text-decoration: none !important; }
      .base-page .top-lists-cards .card-body h3, .base-page .top-lists-cards .card-body h4 {
        text-decoration: none !important;
        font-size: 32.5px !important;
        font-weight: 700 !important;
        line-height: 1.34 !important;
        text-align: center !important;
        margin-bottom: 10px !important; }
      .base-page .top-lists-cards .card-body p {
        text-decoration: none !important;
        max-width: 100% !important;
        height: 71.2px !important;
        font-weight: 400 !important;
        font-size: 20.8px !important;
        line-height: 1.33 !important;
        text-align: center !important; }
      .base-page .top-lists-cards .card-body:hover h3, .base-page .top-lists-cards .card-body:hover h4, .base-page .top-lists-cards .card-body:hover a {
        transition: .3s;
        text-decoration: none !important;
        color: var(--white) !important; }
  .base-page input[type="text"], .base-page input[type="email"], .base-page input[type="number"] {
    height: 48px;
    border-radius: 3px;
    border: solid 1px var(--duck-egg-blue);
    background-color: var(--white); }
    .base-page input[type="text"]:focus, .base-page input[type="text"]:active, .base-page input[type="text"]:hover, .base-page input[type="email"]:focus, .base-page input[type="email"]:active, .base-page input[type="email"]:hover, .base-page input[type="number"]:focus, .base-page input[type="number"]:active, .base-page input[type="number"]:hover {
      border-radius: 3px;
      border: solid 1px var(--greyblue);
      background-color: var(--white); }
  .base-page textarea {
    height: 100px;
    border-radius: 3px;
    border: solid 1px var(--duck-egg-blue);
    background-color: var(--white); }
    .base-page textarea:focus, .base-page textarea:active, .base-page textarea:hover {
      border-radius: 3px;
      border: solid 1px var(--greyblue);
      background-color: var(--white); }
  .base-page input[type="checkbox"] {
    width: 18px;
    height: 18px;
    border-radius: 3px;
    border: solid 1px var(--dark);
    line-height: 1.5; }
  .base-page .btn-primary {
    color: var(--dark);
    vertical-align: middle;
    padding: 12px 35px;
    border-radius: 3px;
    transition: .3s;
    font-size: 16.8px;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    background-color: var(--macaroni-and-cheese);
    border-color: var(--macaroni-and-cheese);
    height: 48px; }
    .base-page .btn-primary:focus, .base-page .btn-primary:active, .base-page .btn-primary:hover {
      box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16);
      border-color: var(--macaroni-and-cheese);
      background-color: var(--sun-yellow); }
  .base-page a {
    color: var(--clear-blue); }
    .base-page a:focus, .base-page a:active, .base-page a:hover {
      text-decoration: none;
      color: var(--sky-blue); }
  .base-page .badge {
    margin-top: 10px;
    margin-bottom: 15px;
    display: inline-block;
    padding: 5px 10px;
    font-size: 10px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 10px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

.icon-small {
  height: 14px;
  width: 14px;
  position: relative;
  top: -2px;
  color: var(--dark); }
  .icon-small.blue {
    fill: var(--clear-blue); }

.icon {
  height: 20px;
  position: relative;
  top: 4px;
  color: var(--dark); }
  .icon.blue {
    fill: var(--clear-blue); }
  .icon.white {
    filter: invert(100%); }
  .icon.icon--small {
    height: 18px;
    margin-left: 5px;
    margin-right: 5px; }
  .icon.icon--inline {
    display: inline; }
  .icon.icon--list {
    margin-bottom: 0;
    height: 22px;
    top: -3px;
    margin-right: 6px; }

.btn-orange {
  font-size: 13.3px;
  font-weight: 700;
  text-decoration: none !important;
  padding: 10px 25px 8px;
  color: var(--dark);
  border-radius: 3px;
  background-color: var(--macaroni-and-cheese);
  text-align: center;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase; }
  .btn-orange:hover, .btn-orange:focus {
    color: var(--dark-grey-blue-two);
    text-decoration: none !important; }

.btn-lg {
  font-size: 16.7px !important;
  font-weight: 700 !important;
  padding: 20px 30px 18px !important; }

a:hover {
  text-decoration: none; }

.container-center {
  width: 780px; }
  .container-center .page-title {
    width: 100%; }

.governance-result {
  float: left; }

.governance-test {
  margin-left: 30px; }

.vs-card {
  display: flex;
  align-items: center;
  justify-content: center; }
  .vs-card h3 {
    margin: 0 !important; }

@media (max-width: 416px) {
  .base-page .page-title {
    width: 100%; }
  .base-page.mission-page h3 {
    margin-top: -16px;
    margin-bottom: 10px; }
  .base-page.mission-page .vs-card {
    padding: 0px; } }

@media (max-width: 668px) {
  .base-page.blog .page-title .btn-secondary {
    margin-bottom: 10px; } }

.star-container img {
  height: 20px; }

.modal .modal-header {
  padding: 21px 27px 10px;
  border-bottom: 0px; }

.modal .modal-body {
  padding: 10px 27px 10px; }

.modal .modal-footer {
  padding: 20px 27px 21px;
  border-top: 0px; }

.modal .modal-title {
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000; }

input, input.form-control {
  border-radius: 3px;
  border: solid 1px var(--greyblue);
  background-color: var(--white);
  font-size: 16.7px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: var(--black);
  padding: 13px 16px;
  width: 296px; }

input[type=checkbox] {
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: solid 1px var(--dark); }

/* Override bootstrap */
.form-check-input {
  margin-top: 0.21rem;
  /* Aligns chrome checkbox with text */ }

.blog .page-title {
  padding-bottom: 20px; }

.blog .post-item p:last-child {
  margin-bottom: 16px; }

.blog .blog-sidebar .card {
  margin-top: 1.5rem;
  border: 0;
  border-top: solid 1px var(--duck-egg-blue);
  padding: 22px 0 0 0; }
  .blog .blog-sidebar .card h3 {
    margin-top: 0; }

.blog .page-previous {
  padding-right: 10px; }

.blog .page-next {
  padding-left: 10px; }

.blog .label a {
  color: var(--black); }

.blog .post-item padding {
  margin-bottom: 16px; }

.blog {
    .page-title {
        padding-bottom: 20px;
    }
    .post-item {
        p:last-child {
            margin-bottom: 16px;
        }
    }
    .blog-sidebar {
        .card {
            margin-top: 1.5rem;
            border: 0;
            border-top: solid 1px var(--duck-egg-blue);
            padding: 22px 0 0 0;
            h3 {
                margin-top: 0;
            }
        }
    }
    .page-previous {
        padding-right: 10px;
    }
    .page-next {
        padding-left: 10px;
    }
    .label {
        a {
            color: var(--black);
        }
    }
    .post-item padding {
        margin-bottom: 16px;
    }
}
label {
  margin-bottom: 0px;
}
ul {
  font-size: 16.7px;
}
p, li {
  font-size: 16.7px;
  color: #000000;
  font-weight: 400;
}
.black {
  color: #000000;
}
a.black:hover {
  color: var(--dark-grey-blue-two);
}
@media (min-width: 1200px) {
  .content-section .container {
    padding-right: 400px;
  }
}
h2, h3, h4 {
  margin-top: 1.5rem;
  &:first-child {
    margin-top: 0;
  }
}
p:last-child {
  margin-bottom: 0px;
}

.indent {
  padding-left: 40px;
}
table {
  font-size: 13.3px;
  border: solid 1px var(--duck-egg-blue);
  thead {
    background-color: var(--ice-blue);
    padding: 10px 15px;
    font-weight: 700;
  }

  tbody {
    tr:nth-of-type(even) {
      background-color: #f1f7f9 !important;
    }
    tr:nth-of-type(odd) {
      background-color: rgba(0,0,0,.0) !important;
    }
  }
  th, td {
    border-top: 0.01px solid transparent !important;
    padding: 19px;
  }
}
.no-break {
  display: inline-block;
}

.no-background-section .container-fluid {
  padding-top: 0px !important;
}
.btn-secondary {
  border-color: var(--ice-blue);
  color: var(--dark) !important;
  background-color: var(--ice-blue);
  padding: 10px 25px 8px;
  margin-right: 10px;
  font-size: 13.3px;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  &:hover,
  &:focus {
    background-color: var(--light-blue-grey);
    border: 1px solid var(--greyblue);
    color: var(--dark) !important;
    text-decoration: none !important;
  }
}
.btn-orange {
  font-size: 13.3px;
  font-weight: 700;
  text-decoration: none !important;
  padding: 10px 25px 8px;
  color: var(--dark);
  border-radius: 3px;
  background-color: var(--macaroni-and-cheese);
  text-align: center;
  &:hover,
  &:focus {
    background-color: var(--sun-yellow);
    border: 1px solid var(--macaroni-and-cheese);
    text-decoration: none !important;
  }
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
}
.btn.btn-neutral {
  min-width: 151px;
  border-radius: 3px;
  background-color: var(--ice-blue) !important;
  font-size: 13.3px;
  font-weight: 600;
  text-transform: uppercase;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: center;
  color: var(--dark);
  box-shadow: 0 1px 2px -1px rgba(255,255,255,0) inset, 0px -2px 0px 0px rgba(0,0,0,0) inset;
  span {
    display: none;
  }
  padding: 11px 16px;
  &:hover {
    background-color: var(--light-blue-grey) !important;
  }
}
.btn-lg {
    font-size: 16.7px !important;
    font-weight: 700 !important;
    padding: 20px 30px 18px !important;
}
.btn-wide {
  min-width: 209px;
}
.btn-icon {
  text-align: left;
  padding-left: 14px;
  padding-right: 12px;
  img {
    float: right;
    margin-bottom: 0;
    height: 24px;
    position: relative;
    top: -5px;
  }
}
.label {
  padding: 0 0 6px;
  text-transform: uppercase;
  border-bottom: 2px solid var(--macaroni-and-cheese);
}
.card-transparent {
  background: transparent;
}
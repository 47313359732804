/* Globals Universal Custom CSS Style */

:root {
  --dark: #182e34;
  --dark-grey-blue-two: #35565f;
  --dark-grey-blue: #28434a;
  --slate: #436a74;
  --metallic-blue: #4e7884;
  --grey-blue: #688c96;
  --greyblue: #81a1aa;
  --light-grey-blue: #a4bcc3;
  --light-blue-grey: #c5d7dc;
  --sapphire: #1a58ac;
  --clear-blue: #2883ff;
  --sky-blue: #76b0ff;
  --carolina-blue: #89bbff;
  --baby-blue: #9cc6ff;
  --very-light-blue: #d5e5ff;
  --pale-grey: #ebf3ff;
  --orange: #fc7f19;
  --mango: #ffa925;
  --macaroni-and-cheese: #ffc12c;
  --maize: #ffd041;
  --sun-yellow: #fee52f;
  --egg-shell: #fff9c3;
  --off-white-two: #fffde7;
  --ice-blue: #e4f0f4;
  --off-white: #f5f2ee;
  --duck-egg-blue: #d7dbdc;
  --white: #ffffff;
  --black: #000000;
}

/* Fonts Custom CSS */

@font-face {
  font-family: 'Nunito Sans';
  src: url('/static/fonts/NunitoSans-ExtraLight.eot');
  src: url('/static/fonts/NunitoSans-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/NunitoSans-ExtraLight.woff2') format('woff2'),
  url('/static/fonts/NunitoSans-ExtraLight.woff') format('woff'),
  url('/static/fonts/NunitoSans-ExtraLight.ttf') format('truetype'),
  url('/static/fonts/NunitoSans-ExtraLight.svg#NunitoSans-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('/static/fonts/NunitoSans-Light.eot');
  src: url('/static/fonts/NunitoSans-Light.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/NunitoSans-Light.woff2') format('woff2'),
  url('/static/fonts/NunitoSans-Light.woff') format('woff'),
  url('/static/fonts/NunitoSans-Light.ttf') format('truetype'),
  url('/static/fonts/NunitoSans-Light.svg#NunitoSans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('/static/fonts/NunitoSans-Black.eot');
  src: url('/static/fonts/NunitoSans-Black.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/NunitoSans-Black.woff2') format('woff2'),
  url('/static/fonts/NunitoSans-Black.woff') format('woff'),
  url('/static/fonts/NunitoSans-Black.ttf') format('truetype'),
  url('/static/fonts/NunitoSans-Black.svg#NunitoSans-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('/static/fonts/NunitoSans-ExtraLightItalic.eot');
  src: url('/static/fonts/NunitoSans-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/NunitoSans-ExtraLightItalic.woff2') format('woff2'),
  url('/static/fonts/NunitoSans-ExtraLightItalic.woff') format('woff'),
  url('/static/fonts/NunitoSans-ExtraLightItalic.ttf') format('truetype'),
  url('/static/fonts/NunitoSans-ExtraLightItalic.svg#NunitoSans-ExtraLightItalic') format('svg');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('/static/fonts/NunitoSans-Italic.eot');
  src: url('/static/fonts/NunitoSans-Italic.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/NunitoSans-Italic.woff2') format('woff2'),
  url('/static/fonts/NunitoSans-Italic.woff') format('woff'),
  url('/static/fonts/NunitoSans-Italic.ttf') format('truetype'),
  url('/static/fonts/NunitoSans-Italic.svg#NunitoSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('/static/fonts/NunitoSans-Bold.eot');
  src: url('/static/fonts/NunitoSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/NunitoSans-Bold.woff2') format('woff2'),
  url('/static/fonts/NunitoSans-Bold.woff') format('woff'),
  url('/static/fonts/NunitoSans-Bold.ttf') format('truetype'),
  url('/static/fonts/NunitoSans-Bold.svg#NunitoSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('/static/fonts/NunitoSans-SemiBold.eot');
  src: url('/static/fonts/NunitoSans-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/NunitoSans-SemiBold.woff2') format('woff2'),
  url('/static/fonts/NunitoSans-SemiBold.woff') format('woff'),
  url('/static/fonts/NunitoSans-SemiBold.ttf') format('truetype'),
  url('/static/fonts/NunitoSans-SemiBold.svg#NunitoSans-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('/static/fonts/NunitoSans-ExtraBold.eot');
  src: url('/static/fonts/NunitoSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/NunitoSans-ExtraBold.woff') format('woff'),
  url('/static/fonts/NunitoSans-ExtraBold.ttf') format('truetype'),
  url('/static/fonts/NunitoSans-ExtraBold.svg#NunitoSans-ExtraBold') format('svg');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('/static/fonts/NunitoSans-Regular.eot');
  src: url('/static/fonts/NunitoSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/NunitoSans-Regular.woff2') format('woff2'),
  url('/static/fonts/NunitoSans-Regular.woff') format('woff'),
  url('/static/fonts/NunitoSans-Regular.ttf') format('truetype'),
  url('/static/fonts/NunitoSans-Regular.svg#NunitoSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('/static/fonts/NunitoSans-BoldItalic.eot');
  src: url('/static/fonts/NunitoSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/NunitoSans-BoldItalic.woff2') format('woff2'),
  url('/static/fonts/NunitoSans-BoldItalic.woff') format('woff'),
  url('/static/fonts/NunitoSans-BoldItalic.ttf') format('truetype'),
  url('/static/fonts/NunitoSans-BoldItalic.svg#NunitoSans-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('/static/fonts/NunitoSans-BlackItalic.eot');
  src: url('/static/fonts/NunitoSans-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/NunitoSans-BlackItalic.woff2') format('woff2'),
  url('/static/fonts/NunitoSans-BlackItalic.woff') format('woff'),
  url('/static/fonts/NunitoSans-BlackItalic.ttf') format('truetype'),
  url('/static/fonts/NunitoSans-BlackItalic.svg#NunitoSans-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('/static/fonts/NunitoSans-SemiBoldItalic.eot');
  src: url('/static/fonts/NunitoSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/NunitoSans-SemiBoldItalic.woff2') format('woff2'),
  url('/static/fonts/NunitoSans-SemiBoldItalic.woff') format('woff'),
  url('/static/fonts/NunitoSans-SemiBoldItalic.ttf') format('truetype'),
  url('/static/fonts/NunitoSans-SemiBoldItalic.svg#NunitoSans-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('/static/fonts/NunitoSans-ExtraBoldItalic.eot');
  src: url('/static/fonts/NunitoSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/NunitoSans-ExtraBoldItalic.woff2') format('woff2'),
  url('/static/fonts/NunitoSans-ExtraBoldItalic.woff') format('woff'),
  url('/static/fonts/NunitoSans-ExtraBoldItalic.ttf') format('truetype'),
  url('/static/fonts/NunitoSans-ExtraBoldItalic.svg#NunitoSans-ExtraBoldItalic') format('svg');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('/static/fonts/NunitoSans-LightItalic.eot');
  src: url('/static/fonts/NunitoSans-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/NunitoSans-LightItalic.woff2') format('woff2'),
  url('/static/fonts/NunitoSans-LightItalic.woff') format('woff'),
  url('/static/fonts/NunitoSans-LightItalic.ttf') format('truetype'),
  url('/static/fonts/NunitoSans-LightItalic.svg#NunitoSans-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}

/* Height */
.vh-10 {
  height: 10vh;
}
.vh-15 {
  height: 15vh;
}
.vh-20 {
  height: 20vh;
}
.vh-25 {
  height: 25vh;
}
.vh-30 {
  height: 30vh;
}
.vh-35 {
  height: 35vh;
}
.vh-40 {
  height: 40vh;
}
.vh-45 {
  height: 45vh;
}
.vh-50 {
  height: 50vh;
}
.vh-55 {
  height: 55vh;
}
.vh-60 {
  height: 60vh;
}
.vh-65 {
  height: 65vh;
}
.vh-70 {
  height: 70vh;
}
.vh-75 {
  height: 75vh;
}
.vh-80 {
  height: 80vh;
}
.vh-85 {
  height: 85vh;
}
.vh-90 {
  height: 90vh;
}
.vh-95 {
  height: 95vh;
}
.vh-100 {
  height: 100vh;
}

/* Width */
.vw-10 {
  width: 10vw;
}
.vw-15 {
  width: 15vw;
}
.vw-20 {
  width: 20vw;
}
.vw-25 {
  width: 25vw;
}
.vw-30 {
  width: 30vw;
}
.vw-35 {
  width: 35vw;
}
.vw-40 {
  width: 40vw;
}
.vw-45 {
  width: 45vw;
}
.vw-50 {
  width: 50vw;
}
.vw-55 {
  width: 55vw;
}
.vw-60 {
  width: 60vw;
}
.vw-65 {
  width: 65vw;
}
.vw-70 {
  width: 70vw;
}
.vw-75 {
  width: 75vw;
}
.vw-80 {
  width: 80vw;
}
.vw-85 {
  width: 85vw;
}
.vw-90 {
  width: 90vw;
}
.vw-95 {
  width: 95vw;
}
.vw-100 {
  width: 100vw;
}

/* General CSS Style */
body {
  font-family: 'Nunito Sans' !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-stretch: normal !important;
}
.bg {
  /* Full height */
  height: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 991.99px) {
  .home.bg {
    max-height: 320px;
  }
}
@media (max-width: 416px) {
  .home.bg {
    background-position: -648px 25px;
    max-height: 376px;
  }
}
.bg-dark {
  background-color: var(--dark) !important;
}
@media(min-width: 768px) {
  .container {
    max-width: 882px;
  }
}
@media (min-width: 992px) {
  .container {
      max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
      max-width: 1140px;
  }
}
@media (max-width: 992px) {
  .container-fluid {
    padding: 50px;
  }
}
.text-success {
  color: var(--macaroni-and-cheese) !important;
}
.text-danger {
  color: var(--slate) !important;
}
input, input.form-control {
  border-radius: 3px;
  border: solid 1px var(--greyblue);
  background-color: var(--white);
  font-size: 16.7px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: var(--black);   
  padding: 13px 16px;
  width: 296px;
}

input[type=checkbox] {
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: solid 1px var(--dark);
}

/* Override bootstrap */
.form-check-input {
    margin-top: 0.21rem; /* Aligns chrome checkbox with text */
}
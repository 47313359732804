/* Custom Splash CSS Style */
.search-header {
  padding: 70px 5% 0 5%;
  .form-inline {
    min-height: 60px;
    margin-top: 40px;
    margin-bottom: 42px;
    .input-group {
      width: 100%;
      align-items: flex-end;
      .form-control {
        border-top: transparent;
        border-left: transparent;
        border-right: transparent;
        border-bottom: solid 1px var(--dark);
        border-radius: 0;
        font-size: 20.8px;
        height: auto;
        padding: 16px 20px;
        &:focus {
          color: #495057;
          background-color: #fff;
          border-top: transparent;
          border-left: transparent;
          border-right: transparent;
          border-bottom: solid 1px var(--dark);
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(0,123,255, 0);
        }
      }
      .input-group-addon {
        border-bottom: solid 1px var(--dark);
        padding-bottom: 20px;
      }
    }
  }
  .filter-by {
    margin-bottom: 56px;
    font-size: 13.3px;
    font-weight: bold;
  }
}
/* PULLED OUT OF SEARCH AND CALCULATOR BOX TO BE USED ELSEWHERE */
.nonprofit-summary {
  font-size: 13.3px;
  font-weight: 400;
  line-height: 1.33;
  margin: 17px auto 25px auto;
  text-align: left;
  .separator {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.impact-audited-nonprofit {
  font-size: 16.7px;
  font-weight: 400;
  line-height: 1.33;
  position: relative;
  top: 5px;
  margin-left: 0px;
  display: inline-block;
  .impact-audited-nonprofit-icon {
    height: 16px;
    display: inline-block;
    position: relative;
    top: 2px;
    margin-right: 6px;
    svg {
      fill: #ffc12c;
    }
  }
}
.search-and-calculator-box {
  background-color: #f5f4f3 !important;
  padding: 0 5% 18px;
  .calculator-box {
    width: 100%!important;
    max-width: 100%!important;
    margin-bottom: 33px;
    height: auto!important;
    .search-filter-container {
      margin-right: 23px;
    }
  }
  .nonprofit_list {
    .card.card-body {
      margin: 5px auto 5px auto;
      padding: 28px 46px 28px 28px;
      border-radius: 3px;
      background-color: var(--white);
      border: solid 1px var(--duck-egg-blue);
      box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16);
      .logo {
        width: 89px;
        height: 89px;
        margin-left: 1px;
        border-radius: 3px;
        border: solid 1px var(--duck-egg-blue);
        background-color: var(--white);
      }
      .rating-stars {
        height: 25px;
      }
      .nonprofit-card {
        display: flex;
        .rate-type {
          text-transform: uppercase;
          opacity: 0.7;
          font-size: 13.3px;
          font-weight: 400;
          line-height: 1.33;
          letter-spacing: 0.5px;
        }
        .nonprofit-title {
          text-decoration: none;
          font-size: 26px;
          font-weight: 800;
          line-height: 1.33;
          &:hover,
          &:focus {
            text-decoration: none;
          }
        }
      }
      .calculator-container {
        position: relative;
        height: 100%;
        padding-left: 48px;
        border-left: solid 1px var(--duck-egg-blue);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &.request-rating {
          /*border-left: solid 1px transparent;*/
        }
        .calculator-card {
          font-size: 26px;
          font-weight: 600;
          line-height: 1.33;
        }
        .footer {
          a.learn-more, a.learn-how {
            color: var(--black);
            text-decoration: underline;
            font-size: 16.7px;
            font-weight: 400;
            line-height: 1.33;
            &:hover, &:focus {
              text-decoration: underline;
              font-size: 16.7px;
              color: var(--black);
            }
          }
        }
      }
      &.ve-card .calculator-card {
        height: 0px;
        margin-bottom: 0px;
        padding-top: 0px !important;
      }
    }
  }
  .page-link {
    position: relative;
    display: block;
    padding: 10px 15px;
    line-height: 1.25;
    color: var(--black);
    margin: 25px 5px;
    border-radius: 3px;
    background-color: var(--macaroni-and-cheese);
    border: 1px solid var(--macaroni-and-cheese);
    &:hover, &:focus {
      border: 1px solid var(--dark-grey-blue-two);
      color: var(--dark-grey-blue-two);
    }
  }
  .page-item {
    &.active {
      .page-link {
        background-color: var(--dark-grey-blue-two);
        border: 1px solid var(--dark-grey-blue-two);
        &:hover, &:focus {
          background-color: var(--dark-grey-blue-two);
          border: 1px solid var(--dark-grey-blue-two);
          color: var(--white);
        }
      }
    }
  }
  .dropdown-toggle .fa-caret-down {
    padding-left: 8px;
  }
  .dropdown-menu {
    margin-top: 2px;
    padding: 0;
    border: 0;
    background: none;
    &:before {
      top: 25px;
    }
    &:after {
      top: 26px;
    }
    .dropdown-menu-container {
      margin-top: 35px;
      padding: 25px 18px 16px 18px;
      background-color: #fff;
      background-clip: padding-box;
      box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16);
      border: solid 1px var(--duck-egg-blue);
      border-radius: 3px;
    }
    .stars {
      position: relative;
      top: -2px;
    }
    .dropdown-item {
      padding: 6px 10px 6px 10px !important;
      height: inherit;
      line-height: 1.33;
      display: flex;
      align-items: center;
      cursor: pointer;
      label {
        display: flex;
        align-items: center;
      }
      span {
        cursor: pointer;
        line-height: 1;
      }
    }
    min-width: 200px;
    .title {
      font-size: 16.7px;
      font-weight: 800;
      line-height: 1.33;
      margin-left: 10px;
      margin-bottom: 10px;
    }
    .mini-block {
      display: table-cell;
      .item {
        width: 357px;
        margin: 0px;
      }
      &:last-child {
        .item {
          width: 357px;
        }
      }
      &:last-child {
        padding-right: 1px;
      }
    }
    input[type=checkbox], input[type=radio] {
      box-sizing: border-box;
      padding: 0;
      width: 18px;
      height: 18px;
      border-radius: 3px;
      border: solid 1px var(--dark);
      background-color: var(--white);
    }
    &.show {
      border-radius: 3px;
      background-color: var(--white);
      border: solid 1px var(--duck-egg-blue);
      box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16);
    }
  }
}
ul.bs-autocomplete-menu {
  box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16);
  border: solid 1px var(--duck-egg-blue);
  background-color: var(--white);
  padding: 25px 17px;
  &:after, &:before {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    transform: rotate(180deg);
    border-top-color: var(--white);
    border-width: 10px;
    left: 50%;
    margin-left: -10px;
  }
  a:hover, a:focus{
    border-radius: 3px;
    background-color: var(--ice-blue);
  }
}
.call-to-action-brand-btn {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16.7px;
  max-width: 250px;
  line-height: 1.33;
  letter-spacing: 0.5px;
  height: 60px;
  border-radius: 3px;
  background-color: var(--macaroni-and-cheese);
  margin: auto;
  padding: 22px 5%;
  color: var(--dark);
  text-decoration: none;
  border: 1px solid var(--macaroni-and-cheese);
  &:hover,
  &:focus {
    border-color: var(--dark);
    background-color: var(--macaroni-and-cheese);
    color: var(--dark);
    text-decoration: none;
  }
}
@media(max-width: 668px) {
  .ve-card .btn.btn-orange {
    display: inline-block !important;
    margin-bottom: 10px !important;
  }
  .ve-card .btn-or {
    position: relative;
    top: -5px;
  }
  .search-and-calculator-box .nonprofit_list .card.card-body {
    padding: 28px;
  }
  .search-and-calculator-box .nonprofit_list .card.card-body .calculator-container {
    border-left: none;
    padding-left: 0;
    .calculator-card {
      padding: 20px 0 0;
    }
  }
  .search-header {
    padding: 30px 0 120px;
    &.page .section-title {
      font-size: 32px;
    }
    .search-nonprofit-input {
      margin-right: 0px !important;
    }
    .filter-by {
      font-size: 13.3px;
      font-weight: bold;
      position: absolute;
      margin-top: 47px;
      left: 30px;
    }
    .form-inline {
      margin-bottom: 0px;
    }
    .btn {
      width: 100%;
      margin-top: 20px;
    }
  }
  .search-and-calculator-box .calculator-box-left-items {
    /* Disabling filters for mobile temporarily */
    display: none;
  }
  .search-and-calculator-box .calculator-box .search-filter-container {
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .search-and-calculator-box {
    padding: 0px 0 7px 0;
  }
}
@media(max-width: 416px) {
  .search-header {
    padding: 25px 0;
  }
}

.calculator-box-right-items {
  .count {
    color: var(--macaroni-and-cheese);
  }
  .input-group-addon {
    color: var(--macaroni-and-cheese);
  }
}
.calculator-number {
}
.calculator-number {
  color: var(--macaroni-and-cheese);
}
.calculator-number-placeholder-wrapper {
  min-width: 17px;
  display: inline-block;
  color: var(--macaroni-and-cheese) !important;
}
.nonprofit-page .calculator-number-placeholder-wrapper {
  min-width: 30px;
}
.top-list-carousel .calculator-number-placeholder-wrapper {
  min-width: 20px;
  display: inline-block;
  color: var(--macaroni-and-cheese) !important;
}
.nonprofit-page .calculator-number-raw.calculator-number-active, 
.nonprofit-page .calculator-number.calculator-number-active,
.nonprofit-page .calculator-number-placeholder-wrapper,
.nonprofit-page .calculator-number-placeholder,
.nonprofit-page .calculator-number {
  color: white !important;
}
.calculator-number-raw {
  display: none;
}
.calculator-number-raw.calculator-number-active, .calculator-number.calculator-number-active {
  color: var(--macaroni-and-cheese);
}
.calculator-card-label {
  color: var(--slate);
  font-size: 13.3px;
  text-transform: uppercase;
  margin-bottom: 4px;
  font-weight: 800;
}
@media(max-width: 668px) {
  .calculator-card-label {
    display: none;
  }
  .search-header .filter-by {
    visibility: hidden;
  }
}
.input-group-addon.prefix {
  width: 9px;
  display: inline-block;
}
@media(max-width: 668px) {
  .search-and-calculator-box .calculator-box {
    height: 89px !important;
  }
}
@media(max-width: 416px) {
  .search-and-calculator-box .calculator-box {
    height: 137px !important;
    min-height: 137px !important;
    max-height: 137px !important;
    margin-bottom: 15px !important;
  }
}


//@media (min-width: 992px) and (max-width: 1049.98px) {}

//@media (min-width: 1050px) and (max-width: 1199.98px) {}

//@media (min-width: 1200px) and (max-width: 1440px) {}

//@media (min-width: 1441px) {}

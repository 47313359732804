/* Custom Splash CSS Style */

section.home-section {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &.bg-blue {
    background: var(--clear-blue);
    a {
      color: var(--sun-yellow) !important;
      &:hover {
        color: var(--maize) !important;
      }
    }
  }
  &.bg-black {
    background: var(--dark);
  }
  &.bg-white {
    background: var(--white);
  }
  .overlay {
    overflow: hidden;
    height: 100%;
    z-index: 2;
    &.blue {
      background: rgba(40, 131, 255, 0.85);
    }
    &.black {
      background: rgba(32, 32, 26, 0.85);
    }
    &.white {
      background: rgba(255, 255, 255, 0.85);
    }
  }
  .container-fluid {
    padding: 8%;
  }
  .icon {
    display: block;
    height: 36px;
    width: 36px;
    margin-bottom:28px;
  }
  .section-title {
    font-size: 40.7px;
    font-weight: 700;
    line-height: 1.33;
  }
  .section-description {
    font-size: 40.7px;
    font-weight: 300;
    line-height: 1.33;
    width: 80%;
  }
  .section-description-small {
    margin-bottom: 48px;
    width: 80%
  }
  .black {
    color: var(--black);
  }
  .white {
    color: var(--white);
    &.icon {
      filter: invert(100%)
      hue-rotate(0deg)
      saturate(0%)
      brightness(1000%)
      contrast(100%);
    }
  }
}
@media(max-width: 416px) {
  section.home-section {
    .section-title {
      font-size: 32.5px;
    }
    .section-description {
      font-size: 26px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
section.media-visibility {
  background-color: var(--dark) !important;
  justify-items: center;
  .container-fluid {
    padding: 90px 100px;
  }
  opacity: 1;
  font-size: 16.7px;
  font-weight: 400;
  line-height: 1.33;
  color: var(--white);
  img {
    height: 30px;
  }
  h6 {
    margin: 0!important;
  }
  .media-logos {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
#carouselLocalGivingTablet,
#carouselLocalGiving {
  margin: 71px auto 0 auto;
}
#carouselLocalGivingMobile {
  margin: 48px auto 0 auto;
}

#pageLocalGiving,
#carouselLocalGivingMobile,
#carouselLocalGivingTablet,
#carouselLocalGiving {
  max-width: 1402px;
  padding: 0;
  .carousel-control-prev {
    left: 0;
    opacity: 1;
    z-index:3;
    &:hover,
    &:focus {
      opacity: 1;
    }
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer !important;
  }
  .carousel-control-next {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer !important;
    right: -40px;
    opacity: 1;
    z-index:3;
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    border-radius: 50%;
    object-fit: contain;
    background-color: var(--white);
    cursor: pointer !important;
  }
  .card-body {
    /*max-height: 441px;*/
    border-radius: 6px;
    position: relative;
    padding: 0;
    border: 0 solid rgba(0,0,0,0);
    .title {
      font-size: 26px;
      font-weight: 400;
      line-height: 1.33;
      text-align: center;
      position: absolute;
      top: 39px;
      z-index: 3;
      width: 100%;
    }
    .find-nonprofit {
      z-index: 3;
      font-size: 16.7px;
      font-weight: 800;
      padding: 20px 10px;
      letter-spacing: 0.5px;
      text-align: center;
      color: var(--dark);
      height: 60px;
      border-radius: 3px;
      background-color: var(--macaroni-and-cheese);
      margin: auto;
      width: 80%;
      position: absolute;
      bottom:29px;
      left: 10%;
      &:hover, &:focus {
        color: var(--dark);
        text-decoration: none;
      }
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 40%;
      background-color: transparent!important;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    }
    img {
      z-index: 1;
      width: 100%;
      border-radius: 6px;
    }
  }
}
.splash-section {
  background-color: #ebf3ff!important;
  height: 65vh;
  min-height: 600px;
}
.splash-sub-section {
  background-color: #f5f4f3 !important;
  min-height: 400px;
}
.splash-title {
  margin-bottom: 20px;
}
.splash-subtitle {
  font-size: 20.8px;
  margin-bottom: 26px;
  color: white;
}
.home {
  opacity: .85;
  padding: 60px 30px 100px;
  background-position-y: -40px;
  background-position-x: 0;
  h1 {
    /*font-size: 63.5px;*/
    color: white;
    font-size: 44px;
    font-weight: 700;
    line-height: 1.03;
    letter-spacing: -0.33px;
  }
  h5 {
    font-size: 26px;
    font-weight: 400;
    line-height: 1.33;
  }
  .call-to-action {
    font-size: 16.7px;
    font-weight: 700;
    padding: 20px 30px;
    letter-spacing: 0.5px;
    text-align: left;
    color: var(--dark);
    border-radius: 3px;
    background-color: var(--macaroni-and-cheese);
    &:hover, &:focus {
      background-color: var(--sun-yellow);
      text-decoration: none;
    }
  }
  .call-to-action-mobile {
    display: none;
  }
  .container {
    width: 980px;
    padding: 0px;
  }
}
@media (max-width: 991.99px) {
  .home {
    padding: 50px 30px;
  }
  h5 {
    display: none;
  }
}
@media (max-width: 416px) {
  .home.splash-landing {
    padding: 40px 0 25px;
    background-image: none !important;
    background-color: #f5f4f3 !important;
    height: auto;
    h1 {
      font-size: 33px;
      color: var(--black);
    }
    .splash-subtitle {
      color: var(--black);
    }
    .call-to-action {
      display: none;
    }
    .call-to-action-mobile {
      display: inline-block;
      font-size: 16.7px;
      font-weight: 700;
      padding: 16px 16px;
      letter-spacing: 0.5px;
      text-align: left;
      color: var(--dark);
      border-radius: 3px;
      background-color: var(--macaroni-and-cheese);
      &:hover, &:focus {
        background-color: var(--sun-yellow);
        text-decoration: none;
      }
    }
    .container {
      padding: 0 20px;
    }
  }
}
.give-confidence,
.local-giving {
  color: var(--white);
}
.give-confidence {
  color: var(--white);
}
.overlay {
  background: rgba(40, 131, 255, 0.85);
  overflow: hidden;
  height: 100%;
  z-index: 2;
}
.local-giving .local-giving-text, .give-confidence .give-confidence-text {
  padding: 100px;
  color: var(--white);
}
.local-giving .local-giving h2, .give-confidence .give-confidence-text h2 {
  font-size: 40.7px;
  font-weight: 800;
}
.local-giving .local-giving-text p, .give-confidence .give-confidence-text p {
  font-size: 40.7px;
  line-height: 1.33;
  font-weight: 400;
}
.local-giving .local-giving-text {
  color: black;
}
.cities {
  padding: 90px;
}
.as-seen-section {
  height: 212px;
  background-color: var(--dark) !important;
  justify-items: center;
  padding: 85px 40px;
  opacity: 1;
}
.as-seen-in {
  text-align: left;
}
@media(max-width: 991px) {
  .as-seen-in {
    text-align: center;
  }
}
.calculator-box {
  margin: -45px auto 0 auto;
  max-width: 980px;
  display: block;
  border-radius: 3px;
  box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16);
  border: solid 1px var(--duck-egg-blue);
  background-color: var(--white);
  font-size: 26px;
  font-weight: 600;
  line-height: 1.33;
  padding: 25px 30px;
}
.calculator-box-left-items {
  margin-right: 15px;
  display: inline-block;
  margin-bottom: 24px;
}
.calculator-box-right-items {
  display: inline-block;
  .qty-plus {
    margin-right: 0;
  }
}
.select-container {
  position: relative;
  display: inline-flex;
  background-color: rgba(248, 248, 248, 0);
  border-bottom: 2px solid var(--macaroni-and-cheese);
  cursor: pointer;
  .fa.fa-caret-down {
    font-size: 15px;
    padding-top: 10px;
    margin-right: 4px;
    margin-left: 8px;
  }
}
#top-list-list {
  /*border-color: rgba(166, 166, 166, 0);
  appearance: none;
  transition: 0.2s;*/
  padding-left: 0;
  padding-right: 15px;
  margin-left: 0;
  background-color: rgba(248, 248, 248, 0);
  overflow: hidden;
  min-width: 30px;
  outline:none;
  border: none;
  appearance: menulist;
  /*margin-left: -8px;*/
  font-size: 26px;
  font-weight: 600;
  cursor: pointer;
  margin-left: -3px;
}
.top-list-dropdown-icon {
  display: none;
}
@media (max-width: 991.99px) {
  .top-list-dropdown-icon {
    display: block;
    height: 20px;
    position: absolute !important;
    top: 5px !important;
    right: 0 !important;
    color: var(--dark);
    /* z-index: -1; */
    pointer-events: none;
    width: 20px;
  }
}
.slider-card {
  border: solid 0 var(--duck-egg-blue);
  font-size: 32.5px;
  line-height: 1.3;
  font-weight: 400;
  color: #000000;
}
#splash-carousel {
  margin: 28.2px auto 48.2px auto;
  max-width: 980px;
  padding: 40px 45px;
  border-radius: 3px;
  background-color: var(--white);
  border: solid 1px var(--duck-egg-blue);
  box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16);
}
@media(max-width: 416px) {
  .splash-landing .splash-sub-section {
    display: none !important;
  }
}
@media(max-width: 416px) {
  #splash-carousel {
    padding: 0px;
    margin: 20px 0;
  }
  .calculator-box {
    padding: 30px;
    min-height: 244px;
  }
  .carousel-item .card-body {
    padding: 30px;
  }
  .slider-card {
    font-size: 20.8px;
    min-height: 265px;
  }
  .nonprofit-summary{
    .summary-geography {
      display: block;
      white-space: normal;
      margin-top: 4px;
    }
    .separator {
      display: none;
    }
  }
}
.carousel-control {
  position: absolute;
  top: 50%;

  /* pushes the icon in the middle of the height */
  z-index: 5;
  display: inline-block;
}
.carousel-control-prev-icon {
  background-image: url('https://s3.amazonaws.com/impactmatters-production/streamline-icons/svg/01-Interface Essential/50-Navigate/navigation-left-circle-1_1.svg');
}
.carousel-control-next-icon {
  background-image: url('https://s3.amazonaws.com/impactmatters-production/streamline-icons/svg/01-Interface Essential/50-Navigate/navigation-right-circle-1.svg');
  display: inline-block;
  width: 40px;
  height: 40px;
}
.carousel-control-prev-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.carousel-control-next {
  justify-content: flex-end;
}
.carousel-control-prev {
  justify-content: flex-start;
}
@media(max-width: 416px) {
  .carousel-control-prev:before, .carousel-control-next:before {
    content: "";
    background-color: white !important;
    font-weight: bold;
    height: 40px;
    width: 39px;
    border-radius: 100px;
    position: absolute;
    z-index: 10;
    border: solid 1px var(--duck-egg-blue);
    box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16);
  }
  .carousel-control-prev:before {
    margin-left: 1px;
  }
  .carousel-control-next:before {
    margin-left: -1px;
  }
  .carousel-control-prev-icon, .carousel-control-next-icon {
    z-index: 20;
  }
}
.carousel {
  .nonprofit-block {
    margin: 0;
  }
}
.splash-sub-section {
  .nonprofit-card {
    margin-top: 22px;
  }
}
.nonprofit-card {
  display: flex;
  width: 100%;

  .logo {
    width: 70px;
    height: 70px;
    border-radius: 3px;
    border: solid 1px var(--duck-egg-blue);
    background-color: var(--white);
  }
}
.splash-sub-section {
  /* margin: 0 23px; */
}
.nonprofit-order-block {
  margin-right: 12px;
  .nonprofit-order {
    background-color: var(--dark);
    color: white;
    font-weight: bold;
    border-radius: 100px;
    width: 24px;
    height: 24px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.nonprofit-block {
  font-weight: 600;
  line-height: 1.1;
  margin: 0 23px 0 0;

  p {
    margin-bottom: 0;
  }

  .title {
    font-size: 20.8px;
    margin-bottom: 4px;
  }

  .address {
    font-size: 13.3px;
  }

  .stars img {
    width: 100px;
    height: 19px !important;
  }
  h4 {
    margin-top: 0px;
  }
}
.section-description.top-list-section {
  margin-bottom: 48px;
}
.top-lists-cards {
  a {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  .card {
    min-height: 315px;
    width: 100%;
    border-radius: 5px;
    border: solid 1px var(--duck-egg-blue);
    background-color: var(--white);
    margin: 0 26px 22px 0;
    padding: 82px 20px 40px 20px;
    text-align: center;
    .icon{
      font-size: 36px;
      margin: 0 auto 28px;
    }
    &.crowded {
      padding: 42px 20px 40px 20px;
      vertical-align: middle;
    }
    &.no-bottom-margin {
      margin-bottom: 0px;
    }
    color: var(--dark);
    &:hover,
    &:focus {
      .icon{
        font-size: 36px;
        color: var(--white);
        transition: 0.5s;
        filter: invert(100%);
      }
      transition: 0.5s;
      /*margin: 0 26px 0 0;*/
      text-decoration: none;
      border-radius: 5px;
      color: var(--white);
      box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16);
      border: solid 1px var(--duck-egg-blue);
      background-color: var(--clear-blue);
      object {
        transition: 1s;
        filter: invert(100%)
          hue-rotate(0deg)
          saturate(0%)
          brightness(1000%)
          contrast(100%);
      }
    }
    object.icon {
      width: 38px;
      height: 36px;
      object-fit: contain;
      margin: 0 auto 35px auto;
    }
    .title {
      min-height: 40.8px;
      font-size: 32.5px;
      font-weight: 700;
      line-height: 1.34;
      text-align: center;
      margin-bottom: 10px;
    }
    .description {
      max-width: 100%;
      min-height: 71.2px;
      font-weight: 400;
      font-size: 20.8px;
      line-height: 1.33;
      text-align: center;
    }
  }
  .col-lg-4:last-child {
    .card {
      margin-bottom: 0px;
    }
  }
}
@media(max-width: 992px) {
  .top-lists-cards {
    .card {
      &.no-bottom-margin {
        margin-bottom: 22px;
      }
    }
  }
  .media-visibility .col-md-12 {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.analyze-nonprofits {
  a, a:hover, a:active {
    text-decoration: none;
  }
  .container {
    .icon {
      transition: .12s;
      width: 36px;
      height: 36px;
      filter:
              invert(100%)
              hue-rotate(0deg)
              saturate(0%)
              brightness(1000%)
              contrast(100%);
    }
    .nonprofit-analysis {
      .chapter {
        width: 100%;
        height: 54px;
        font-size: 32.5px;
        font-weight: 400;
        line-height: 1.34;
        color: var(--white);
        padding-right: -131px;
        .btn {
          width: 131px;
          height: 40px;
          border-radius: 3px;
          background-color: var(--carolina-blue);
          float: right;
          &:hover,
          &:focus {
            background-color: var(--sky-blue);
            border: 1px solid var(--sky-blue);
          }
          .text {
            text-transform: uppercase;
            width: 98px;
            vertical-align: bottom;
            height: 17.7px;
            font-size: 13.3px;
            font-weight: 600;
            line-height: 1.33;
            letter-spacing: 0.5px;
            text-align: center;
            color: var(--white);
          }
        }
      }
    }
    .rating-process-row {
      padding: 22px 0 24px;
      border-bottom: solid 1px rgba(255, 255, 255, .5);
      &.first {
        border-top: 1px solid var(--white);
      }
      .step-number {
        opacity: 0.49;
        font-size: 16.7px;
        font-weight: 400;
        line-height: 1.33;
      }
      .step {
        display: flex;
        font-size: 26px;
        font-weight: 800;
        line-height: 1.33;
        .icon-step {
          margin-right: 14px;
          height: 25px!important;
          width: 25px!important;
        }
      }
      .step-detail {
        font-size: 20.8px;
        font-weight: 400;
        line-height: 1.33;
        margin-bottom: 0;
        color: white;
      }
    }
    .see-all-ratings {
      padding: 15px 25px;
      border-radius: 3px;
      transition: .3s;
      font-size: 16.8px;
      font-weight: 700;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      background-color: rgba(255, 255, 255, 0.21);
      &:hover,
      &:focus {
        background-color: rgba(255, 255, 255, 0);
        border: 1px solid rgba(255, 255, 255, 0.21);
      }
    }
  }
}
@media(max-width: 416px) {
  .analyze-nonprofits .container .rating-process-row.first {
    border-top: none;
  }
  .analyze-nonprofits .container .rating-process-row.last {
    border-bottom: none;
    padding-bottom: 0px;
  }
}
.explore-impact-ratings {
  h2 {
    margin-bottom: 20px;
  }
}

/* Input numbers */

.count {
  color: #000;
  display: inline-block;
  vertical-align: middle;
  width: 93px;
  margin-bottom: 4px;
}
.top-list-cards-section {
  .count {
    width: 85px;
  }
}
.qty-plus,
.qty-minus {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  color: #000;
  width: 30px;
  height: 30px;
  margin: 0 8px;
  text-align: center;
  border-radius: 50%;
  background-clip: padding-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  object-fit: contain;
  &:hover, &:active {
    background-color: rgba(228, 240, 244, 0.25) !important;
  }
  img {
    height: 30px;
    width: 30px;
  }
}
.qty {
  white-space: nowrap;
}

/*Prevent text selection*/

input#calculator-amount {
  border: 0;
  background-color: transparent;
  font-size: 26px;
  font-weight: 600;
  outline: none;
  opacity: inherit;
  border-radius: inherit;
  line-height: inherit;
  letter-spacing: normal;
  padding: 0;

  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:disabled {
    background-color: white;
  }
}
.input-group.prefix {
  display: inline;
  vertical-align: top;
  border-bottom: 2px solid var(--macaroni-and-cheese);
}

/* MEDIA QUERIES */
@media (max-width: 991.99px) {
  .splash-section {
    background-color: #ebf3ff !important;
    height: 70vh;
  }
  #carouselLocalGiving {
    display: none;
  }
  #carouselLocalGivingTablet {
    display: none;
  }
  #carouselLocalGivingMobile {
    display: block;
  }
  .calculator-box {
    margin: -70px auto 0 auto;
  }
  .carousel-control-next {
    right: -18px;
    opacity: 1;
  }
  .carousel-control-prev {
    left: -18px;
    opacity: 1;
  }
  section.media-visibility {
    h6 {
      line-height: 2;
      height: 25px;
    }
    img {
      height: 30px;
      margin-top: 15px;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .calculator-box {
    .calculate-impact-on {
      display: block
    }
  }
  #carouselLocalGiving {
    display: none;
  }
  #carouselLocalGivingTablet {
    display: block;
  }
  #carouselLocalGivingMobile {
    display: none;
  }
  .carousel-control-next {
    right: -65px;
    opacity: 1;
  }
  .carousel-control-prev {
    left: -65px;
    opacity: 1;
  }
  section.media-visibility {
    h6{
      line-height: 2;
      height: 25px;
    }
    img {
      height: 25px;
    }
  }
}
@media (min-width: 1200px) {
  .calculator-box {
    .message {
      display: flex;
      justify-content: space-between;
    }
    .calculator-box-left-items {
      margin-bottom: 0;
    }
  }
  #carouselLocalGiving {
    display: block;
  }
  #carouselLocalGivingTablet {
    display: none;
  }
  #carouselLocalGivingMobile {
    display: none;
  }
  .carousel-control-next {
    right: -122px;
    opacity: 1;
  }
  .carousel-control-prev {
    left: -122px;
    opacity: 1;
  }
  section.media-visibility {
    h6{
      line-height: 2.2;
      height: 30px;
    }
    img {
      height: 30px;
    }
  }
}
@media(max-width: 416px) {
  .calculator-box {
    margin: 20px auto 0 auto;
  }
}

@media (max-width: 992px) {
  section.home-section .section-description, section.home-section .section-description-small {
    width: 100%
  }
  .analyze-nonprofits .container .nonprofit-analysis .chapter {
    height: auto;
  }
  .analyze-nonprofits .container .nonprofit-analysis .chapter .btn {
    float: none;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (min-width: 992px) {
  section.home-section {
    .container-fluid {
      padding: 100px;
    }
  }
}
@media (min-width: 1199.98px) and (max-width: 1280px) {
  .find-nonprofit {
    padding: 20px!important;
  }
}
@media (max-width: 590px) {
  .find-nonprofit {
    font-size: 16.7px!important;
    font-weight: 600!important;
    padding: 20px!important;
    letter-spacing: 0!important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  section.home-section {
    .container-fluid {
      padding: 50px;
    }
  }
  section.media-visibility {
    /*height: 140px;*/
    background-color: var(--dark) !important;
    justify-items: center;
    font-size: 13.7px;
    .container-fluid {
      padding: 0px;
    }
    h4 {
      font-size: 13.7px;
    }
    img {
      height: 20px;
    }
  }
}
@media (max-width: 768px) {
  section.media-visibility {
    /*height: 212px;*/
    background-color: var(--dark) !important;
    justify-items: center;
    padding: 41px 5px 41px 5px;
    .container-fluid {
      padding: 0px;
    }
    h4 {
      font-size: 13.7px;
    }
  }
  section.home-section {
    .container-fluid {
      padding: 50px 15px;
    }
  }
  .container {
    max-width: 90% !important;
  }
}

@media (max-width: 416px) {
  .container {
    max-width: 100% !important;
  }
  .qty-minus {
    margin-left: 0px;
  }
}

//@media (min-width: 992px) and (max-width: 1049.98px) {}

//@media (min-width: 1050px) and (max-width: 1199.98px) {}

//@media (min-width: 1200px) and (max-width: 1440px) {}

//@media (min-width: 1441px) {}

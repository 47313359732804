.base-page {
  font-family: "Nunito Sans" !important;
  font-weight: 400 !important;
  line-height: 1.33 !important;
  color: #000000 !important;
  .container-fluid {
    padding: 50px 30px !important;
  }
  .container {
    padding: 56px 30px;
    &.container-skinny {
      max-width: 882px;
      .page-title {
        width: 100%;
      }
    }
  }
  .page-title {
    padding-bottom: 36px;
    width: 80%;
    img, .icon {
      height: 36px;
      margin-bottom: 10px;
    }
    .badge {
      font-size: 13px !important;
      font-weight: 500 !important;;
      line-height: 1.33 !important;;
      padding: 5px 10px !important;;
      border-radius: 5px !important;
    }
    h1 {
      font-size: 40.7px;
      font-weight: 800;
      line-height: 1.33;
    }
    p {
      font-size: 20.8px !important;;
      font-weight: 300 !important;
      line-height: 1.33;
    }
  }
  .rating-stars {
    margin: 10px auto 15px auto;
  }
  p {
    font-size: 16.7px !important;
    font-weight: 400 !important;
    line-height: 1.33 !important;
  }
  h1 {
    font-family: "Nunito Sans" !important;
    font-size: 40.7px !important;
    font-weight: 800 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.34 !important;
    letter-spacing: normal !important;
    color: var(--dark) !important;
  }
  h2, h3, h4 {
    margin-top: 1.5rem;
  }
  h2 {
    font-family: "Nunito Sans" !important;
    font-size: 32.5px !important;
    font-weight: 800 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.34 !important;
    letter-spacing: normal !important;
    color: var(--dark) !important;
  }
  h3 {
    font-family: "Nunito Sans" !important;
    font-size: 26px !important;
    font-weight: 700 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.33 !important;
    letter-spacing: normal !important;
    color: var(--dark) !important;
  }
  h4 {
    font-family: "Nunito Sans" !important;
    font-size: 20.8px;
    font-weight: 600 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.33 !important;
    letter-spacing: normal !important;
    color: #000 !important;
    &.title {
      font-size: 26px !important;
      font-weight: 700 !important;
    }
  }
  h5 {
    font-family: "Nunito Sans" !important;
    font-size: 16.7px;
    line-height: 1.33;
    letter-spacing: normal;
    font-weight: 700 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    color: #000 !important;
  }
  h6 {
    font-family: "Nunito Sans" !important;
    font-size: 16.7px;
    font-weight: 400;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.33 !important;
    letter-spacing: normal !important;
    color: #000 !important;
    &.card-title {
      margin-top: 15px;
      margin-bottom: 10px;
      font-size: 20.8px;
      font-weight: 600;
      font-stretch: normal !important;
    }
  }
  .card-header {
    background-color: var(--ice-blue);
    font-family: "Nunito Sans" !important;
    font-size: 16.7px;
    font-weight: 600;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.33 !important;
    letter-spacing: normal !important;
    color: var(--dark) !important;
  }
  .top-lists-cards {
    margin-bottom: 50px;
    a {
      text-decoration: none !important;
    }
    .card-body {
      text-align: center;
      text-decoration: none !important;
      h3, h4 {
        text-decoration: none !important;
        font-size: 32.5px !important;
        font-weight: 700 !important;
        line-height: 1.34 !important;
        text-align: center !important;
        margin-bottom: 10px !important;
      }
      p {
        text-decoration: none !important;
        max-width: 100% !important;
        height: 71.2px !important;
        font-weight: 400 !important;
        font-size: 20.8px !important;
        line-height: 1.33 !important;
        text-align: center !important;
      }
      &:hover {
        h3, h4, a {
          transition: .3s;
          text-decoration: none !important;
          color: var(--white)!important;
        }
      }
    }

  }
  input[type="text"], input[type="email"], input[type="number"] {
    height: 48px;
    border-radius: 3px;
    border: solid 1px var(--duck-egg-blue);
    background-color: var(--white);
    &:focus, &:active, &:hover {
      border-radius: 3px;
      border: solid 1px var(--greyblue);
      background-color: var(--white);
    }
  }
  textarea {
    height: 100px;
    border-radius: 3px;
    border: solid 1px var(--duck-egg-blue);
    background-color: var(--white);
    &:focus, &:active, &:hover {
      border-radius: 3px;
      border: solid 1px var(--greyblue);
      background-color: var(--white);
    }
  }
  input[type="checkbox"] {
    width: 18px;
    height: 18px;
    border-radius: 3px;
    border: solid 1px var(--dark);
    line-height: 1.5;
  }
  .btn-primary {
    color: var(--dark);
    vertical-align: middle;
    padding: 12px 35px;
    border-radius: 3px;
    transition: .3s;
    font-size: 16.8px;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    background-color: var(--macaroni-and-cheese);
    border-color:  var(--macaroni-and-cheese);
    height: 48px;
    &:focus, &:active, &:hover {
      box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16);
      border-color: var(--macaroni-and-cheese);
      background-color: var(--sun-yellow);
    }
  }
  a {
    color: var(--clear-blue);
    &:focus, &:active, &:hover {
      text-decoration: none;
      color: var(--sky-blue);
    }
  }
  .badge {
    margin-top: 10px;
    margin-bottom: 15px;
    display: inline-block;
    padding: 5px 10px;
    font-size: 10px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 10px;
    transition: color .15s ease-in-out,background-color
                .15s ease-in-out,border-color
                .15s ease-in-out,box-shadow
                .15s ease-in-out;
  }
}
.icon-small {
  height: 14px;
  width: 14px;
  position: relative;
  top: -2px;
  color: var(--dark);
  &.blue {
    fill: var(--clear-blue);
  }
}
.icon {
  height: 20px;
  position: relative;
  top: 4px;
  color: var(--dark);
  &.blue {
    fill: var(--clear-blue);
  }
  &.white {
    filter: invert(100%);
  }
  &.icon--small {
    height: 18px;
    margin-left: 5px;
    margin-right: 5px;
  }
  &.icon--inline {
    display: inline;
  }
  &.icon--list {
    margin-bottom: 0;
    height: 22px;
    top: -3px;
    margin-right: 6px;
  }
}
.btn-orange {
  font-size: 13.3px;
  font-weight: 700;
  text-decoration: none !important;
  padding: 10px 25px 8px;
  color: var(--dark);
  border-radius: 3px;
  background-color: var(--macaroni-and-cheese);
  text-align: center;
  &:hover,
  &:focus {
    color: var(--dark-grey-blue-two);
    text-decoration: none !important;
  }
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
}
.btn-lg {
    font-size: 16.7px !important;
    font-weight: 700 !important;
    padding: 20px 30px 18px !important;
}
a:hover {
  text-decoration: none;
}
.container-center {
  width: 780px;
  .page-title {
    width: 100%;
  }
}
.governance-result {
  float: left;
}
.governance-test {
  margin-left: 30px;
}
.vs-card {
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    margin: 0 !important;
  }
}
@media(max-width: 416px) {
  .base-page {
    .page-title {
      width: 100%;
    }
    &.mission-page {
      h3 {
        margin-top: -16px;
        margin-bottom: 10px;
      }
      .vs-card {
        padding: 0px;
      }
    }
  }
}
@media(max-width: 668px) {
  .base-page {
    &.blog {
      .page-title {
        .btn-secondary {
          margin-bottom: 10px;
        }
      }
    }
  }
}

/* Custom Splash CSS Style */
.page {
  #pageLocalGiving {
    .top-list-carousel {
      .card-body {
        margin-bottom: 27px!important;
        img {
          /*max-height: 441px;*/
        }
      }
    }
  }
}
.local-giving-guide-cards-section {
  padding-bottom: 56px;
}
@media(max-width: 668px) {
  .local-giving-guide-cards-section {
    padding-bottom: 0px;
  }
}
#pageLocalGivingMobile {
  display: none;
}
@media(max-width: 668px) {
  #pageLocalGiving {
    display: none;
  }
  #pageLocalGivingMobile {
    display: block
  }
}
.local-giving-guide-cards-section {
  .select-container {
    a {
      color: var(--black);
    }
    margin-right: 20px;
  }
}
.local-giving-block {
  margin-top: 48px;
  .page-title {
    /*padding-top: 20px;*/
  }
}
@media(max-width: 376px) {
  .local-giving-block {
    margin-bottom: 20px;
  }
}
.local-guides {
  .container-fluid {
    padding: 79px 100px 83px 100px;
  }
  .calculator-box {
    margin-top: 45px;
    margin-bottom: 0;
  }
  .filter-by {
    height: 22px;
    opacity: 0.58;
    font-size: 13.3px;
    font-weight: 600;
    line-height: 1.33;
    letter-spacing: 0.5px;
    margin-top: 37px;
    margin-bottom: 11px;
    text-transform: uppercase;
  }
  .local-giving-block {
    .header {
      .icon {
        margin-bottom: 11px;
      }
    }
  }
  .cause-anchor {
    position: relative;
    top: -30px;
  }
}
@media(max-width: 668px) {
  .local-guides {

  }
  .local-guides .calculator-box {
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .local-guide:last-child .nonprofit_list .row:last-child .card {
    margin-bottom: 0px;
  }
}
@media(max-width: 416px) {
  .local-guides {

  }
  .local-guides .calculator-box {
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .local-guide:last-child .nonprofit_list .row:last-child .card {
    margin-bottom: 0px;
  }
}
@media (max-width: 376px) {
  .sub-page .local-giving-guide-cards-section .calculator-box {
    min-height: 197px;
  }
}
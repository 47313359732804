/* Custom Splash CSS Style */
.page {
  .header {
    .container-fluid {
      padding: 40px 100px 40px 100px;
    }
    .page-title {
      text-align: left;
      background-color: var(--white);
      width: 80%;
      .icon {

      }
      h1 {
        font-size: 40.7px;
        font-weight: 800;
        line-height: 1.33;
        margin-bottom: 0px;
      }
      p {
        font-size: 32.5px;
        font-weight: 400;
        line-height: 1.33;
      }
      p:last-child {
        margin-bottom: 0px;
      }
      p.normal {
        font-size: 20.8px;
        font-weight: 300;
      }
    }
  }
  .toplist-page {
    .container-fluid {
      padding: 48px 100px;
    }
  }
  .icon {
    display: block;
    height: 36px;
    margin-bottom:28px;
    color: var(--clear-blue);
  }
  .section-title {
    font-size: 40.7px;
    font-weight: 700;
    line-height: 1.33;
  }
  .section-description {
    font-size: 40.7px;
    font-weight: 300;
    line-height: 1.33;
  }
  .black {
    color: var(--black);
  }
  .white {
    color: var(--white);
  }
}
.sub-page {
  background-color: transparent !important;
  .header {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .overlay {
      background: rgba(40, 131, 255, 0.65);
    }
    .container-fluid {
      padding: 20px 100px 65px 100px;
    }
    .breadcrumb-page {
      margin-bottom: 35px;
      a {
        font-size: 13.3px;
        font-weight: 300;
        letter-spacing: normal;
        line-height: 1.33;
        margin-left: 1px;
      }
      i {
        font-size: 13.3px;
      }
    }
    .page-title {
      width: 80%;
      .icon {
        display: block;
        height: 36px;
        margin-bottom:10px;
      }
      text-align: left;
      h1 {
        font-size: 40.7px;
        font-weight: 800;
        line-height: 1.33;
      }
      p {
        font-size: 20.8px;
        font-weight: 400;
        line-height: 1.33;
        margin-top: 18px;
      }
    }
  }
  .section-title {
    font-size: 40.7px;
    font-weight: 700;
    line-height: 1.33;
  }
  .section-description {
    font-size: 40.7px;
    font-weight: 300;
    line-height: 1.33;
  }
  .calculator-box {
    max-width: 100%;
    margin-bottom: 42px;
    height: 90px;
  }
  .top-list-cards-section {
    background-color: #f5f4f3;
    .container-fluid {
      padding: 0 100px 68px 100px;
    }
  }
}
@media(max-width: 668px) {
  .sub-page {
    .calculator-box {
      height: auto;
      min-height: 148px;
    }
  }
}
@media(max-width: 376px) {
  .sub-page {
    .calculator-box {
      height: auto;
      min-height: 226px;
    }
  }
}
@media(max-width: 992px) {
  .page {
    .header {
      .container-fluid {
        padding: 40px 50px;
      }
      .page-title {
        width: 100%;
      }
      .container {
      }
    }
    .toplist-page {
      .container-fluid {
        padding: 40px 50px;
      }
      .container {
      }
    }
  }

}

/* moving these out to be accessible without all the other styles coming along */



      .passes-or-fails-governance-label {
        padding: 5px 8px 3px;
        border-radius: 3px;
        /* border: 1px solid #81a1aa; */
        text-transform: uppercase;
        font-size: 13.3px;
        color: var(--dark);
        font-weight: 400;
        letter-spacing: 0.5px;
        object {
          background-color: var(--white);
          margin-right: 2px;
          svg {
            fill: var(--dark)
          }
        }
        white-space: nowrap;
      }
      .passes-governance {
        background-color: var(--ice-blue);
        svg {
        }
      }

      .fails-governance {
        background-color: var(--dark);
        color: var(--white);
        svg {
        }
      }

      .nonprofit-label-icon {
        float: left;
        height: 20px !important;
        width: 20px !important;
      }
      .nonprofit-impact-line {
        margin-bottom: 8px;
        margin-left: 32px;
        padding-top: 2px;
        line-height: 1.33;
      }
      .nonprofit-governance-line {
        margin-left: 32px;
        padding-top: 2px;
        line-height: 1.33;
      }
      .nonprofit-label {
        font-weight: 800;
        font-size: 16.7px;
      }
      .nonprofit-governance-label {
        margin-right: 8px;
        display: inline-block;
        padding-bottom: 4px;
      }
      .nonprofit-value {
        font-weight: 400;
        font-size: 16.7px;
        line-height: 1.6;
      }

.nonprofit_list {
  .card-body {
    margin: 5px auto 5px auto;
    padding: 28px;
    border-radius: 3px;
    background-color: var(--white);
    border: solid 1px var(--duck-egg-blue);
    box-shadow: 0 0 10px 0 rgba(24, 46, 52, 0.16);
    .logo {
      width: 89px;
      height: 89px;
      border-radius: 3px;
      border: solid 1px var(--duck-egg-blue);
      background-color: var(--white);
    }
    .rating-stars {
      height: 25px;
    }
    .nonprofit-card {
      display: flex;
      .rate-type {
        text-transform: uppercase;
        opacity: 0.7;
        font-size: 13.3px;
        font-weight: 400;
        line-height: 1.33;
        letter-spacing: 0.5px;
      }
      .nonprofit-title {
        text-decoration: none;
        font-size: 26px;
        font-weight: 800;
        line-height: 1.33;
        &:hover,
        &:focus {
          text-decoration: none;
          color: var(--dark-grey-blue-two);
        }
      }
      .nonprofit-summary {
        font-size: 13.3px;
        font-weight: 400;
        line-height: 1.33;
        margin: 17px auto 25px auto;
        text-align: left;
        .separator {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
    .calculator-container {
      position: relative;
      height: 100%;
      padding-left: 48px;
      border-left: solid 1px var(--duck-egg-blue);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &.request-rating {
        /*border-left: solid 1px transparent;*/
        flex-direction: column;
        justify-content: center;
      }
      .calculator-card {
        font-size: 26px;
        font-weight: 600;
        line-height: 1.33;
      }
      .footer {
        a.learn-more {
          color: var(--black);
          text-decoration: underline;
          font-size: 16.7px;
          font-weight: 400;
          line-height: 1.33;
          &:hover, &:focus {
            text-decoration: underline;
            font-size: 16.7px;
            color: var(--black);
          }
        }
      }
      .btn-orange {
        margin-right: 10px;
      }
    }
  }
}
.white {
  color: var(--white)!important;
}
object.icon.white {
  filter: invert(100%)
  hue-rotate(0deg)
  saturate(0%)
  brightness(1000%)
  contrast(100%);
}
@media(max-width: 668px) {
  .sub-page .header .page-title {
    width: 100%;
  }
  .nonprofit_list .card-body .nonprofit-card {
    display: block;
  }
  .nonprofit-order-block {
    margin-bottom: 14px;
  }
  .nonprofit-block {
    margin: 0
  }
  .nonprofit_list .card-body .calculator-container {
    border-left: none;
    padding-left: 0;
    .calculator-card {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid var(--duck-egg-blue);
      font-size: 16.7px !important;
    }
  }
  .nonprofit_list .card-body.ve-card .footer {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid var(--duck-egg-blue);
  }
  .nonprofit_list .card-body .calculator-container .calculator-card {
    margin-bottom: 20px;
  }
  .sub-page .calculator-box {
    margin-bottom: 15px;
  }
}
.top-list-blurb {
  margin-bottom: 96px;
}
@media(max-width: 476px) {
  .top-list-blurb {
    margin-bottom: 0px;
  }
}

.sidebar {
  padding-left: 40px;
  /*.card {
    margin-top: 25px;
  }*/
  .title {
      font-size: 26px;
      font-weight: bold;
      margin-bottom: 25px;
      margin-top: -6px;
  }
  .card {
    margin-bottom: 25px;
    border: solid 1px var(--duck-egg-blue);
    border-radius: 3px;
  }
}
@media(max-width: 992px) {
  .sidebar {
    padding-left: 15px;
    padding-top: 40px;
  }
}
@media(max-width: 668px) {
  .sidebar {
    display: none;
  }
}

.sidebar-section {
    margin-bottom: 45px;
}

.sidebar-links {
    border-top: solid 1px var(--duck-egg-blue);
    padding-top: 22px;
}

.sidebar .sidebar-rating-card {
    img {
        height: 17px;
    }
    h5 {
        font-size: 20.8px;
        margin-bottom: 0px;
    }
    .rating-stars {
        margin-top: 6px;
    }
    .nonprofit-impact-line {
      margin-bottom: 0px;
    }
    .separator {
        padding: 0 10px;
    }
}